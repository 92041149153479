<template>
  <div class="container mt-55">
    <div class="row">
      <div class="col-12 text-right">
        <div class="card">
          <div class="card-header">
            <h5 class="hk-sec-title">خدمات</h5>
            <p class="mb-25">
              افزونه بورس تهران ساخته شده تا معاملات الگوریتمی را در بورس ایران
              هرچه بیشتر عملیاتی کند. برای کمک به این فرایند ما در نظر داریم
              دسترسی به داده ها را آسان کنیم تا تمرکز متفکران عرصه ی معاملات
              الگوریتمی به فرایند تصمیم گیری معطوف شود.
            </p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm">
                <div class="row">
                  <div class="col-4">
                    <div id="list-tab" class="list-group" role="tablist">
                      <a
                        id="list-home-list"
                        aria-controls="list-home"
                        aria-selected="false"
                        class="list-group-item list-group-item-action"
                        data-toggle="list"
                        href="#list-home"
                        role="tab"
                        >تحلیل بازار</a
                      >
                      <a
                        id="list-export-list"
                        aria-controls="list-export"
                        aria-selected="true"
                        class="list-group-item list-group-item-action active"
                        data-toggle="list"
                        href="#list-export"
                        role="tab"
                        >خروجی داده</a
                      >
                      <a
                        id="list-laboratory-list"
                        aria-controls="list-laboratory"
                        aria-selected="false"
                        class="list-group-item list-group-item-action"
                        data-toggle="list"
                        href="#list-laboratory"
                        role="tab"
                        >آزمایشگاه</a
                      >
                      <a
                        id="list-settings-list"
                        aria-controls="list-settings"
                        aria-selected="false"
                        class="list-group-item list-group-item-action"
                        data-toggle="list"
                        href="#list-settings"
                        role="tab"
                        >مشارکت</a
                      >
                    </div>
                  </div>
                  <div class="col-8">
                    <div id="nav-tabContent" class="tab-content">
                      <div
                        id="list-home"
                        aria-labelledby="list-home-list"
                        class="tab-pane fade"
                        role="tabpanel"
                      >
                        <h5>
                          <i
                            class="fad fa-abacus font-18 text-light-50 ml-10"
                          ></i>
                          تحلیل بازار
                        </h5>
                        <br />
                        <p>
                          با استفاده از داده های دریافتی از بازار بورس تهران و
                          با کمک گرفتن از روش های موجود یا انحصاری، افزونه ی
                          بورس تهران اقدام به تحلیل نماد های بازار کرده و داده
                          های تحلیل شده خود را با تمامی معامله گران به اشتراک می
                          گذارد.
                          <br />
                          <br />
                          تمامی داده های تحلیل شده توسط بخش جست و جو در صفحه
                          اصلی در دسترس است.
                          <br />
                          <br />
                          در صورت هرگونه نقد، پیشنهاد در مورد نتایج تحلیل ها،
                          لطفا در بخش پشتیبانی با ما در میان بگذارید.
                          <br />
                          <br />
                        </p>
                        <div class="alert alert-danger fade show">
                          <h4 class="alert-heading mb-5">توجه</h4>
                          <p>
                            تمامی تحلیل های ارائه شده توسط افزونه بورس تهران جهت
                            اطلاعات عمومی است و هیچ یک از تحلیل ها به منزله ی
                            توصیه و سیگنال نیست و افزونه بورس تهران هیچ گونه
                            مسئولیتی در مقابل استفاده از تحلیل ها نمی پذیرد.
                          </p>
                        </div>
                      </div>
                      <div
                        id="list-export"
                        aria-labelledby="list-export-list"
                        class="tab-pane active show"
                        role="tabpanel"
                      >
                        <h5>
                          <i
                            class="fad fa-cloud-download font-18 text-light-50 ml-10"
                          ></i>
                          خروجی داده
                        </h5>
                        <br />
                        <p>
                          علیرغم میل باطنی ما به فراهم کردن تمامی امکانات برای
                          کاربران، سرور ما امکان پشتیبانی از تمامی فرایند ها را
                          به شکل عمومی ندارد. اما این امکان برای شما فراهم است
                          تا داده ها را به شکل جداگانه دریافت کرده و بر روی
                          سیستم یا سرور شخصی خود به پردازش آن ها بپردازید و از
                          مراحل کرال کردن داده ها خلاص شوید. برای این منظور ما
                          تمام اطلاعاتی که با موفقیت دریافت کرده ایم را به شکل
                          یکجا یا روزانه به رایگان در اختیار شما قرار می دهیم.
                          <br />
                        </p>
                        <div
                          class="my-20 alert alert-warning alert-wth-icon alert-dismissible fade show"
                        >
                          <span
                            class="alert-icon-wrap"
                            style="left: unset !important; right: 0"
                          >
                            <i
                              class="fad fa-lightbulb-exclamation text-warning"
                            ></i>
                          </span>
                          <h5 class="alert-heading">بروز رسانی داده ها</h5>
                          <p>
                            اطلاعات ارائه شده بلادرنک نیستند و بعد از زمان بازار
                            جمع آوری می شوند
                          </p>
                          <button
                            aria-label="Close"
                            class="close"
                            data-dismiss="alert"
                            style="right: unset !important; left: 0"
                            type="button"
                          >
                            <span aria-hidden="true" class="text-warning"
                              >×</span
                            >
                          </button>
                        </div>
                        <br />

                        <p>سه روش کلی برای دریافت داده ها وجود دارد:</p>

                        <ul class="list-group list-group-flush text-right mt-4">
                          <li class="list-group-item">
                            <i
                              class="fad fa-globe-asia font-18 text-light-50 ml-10"
                            ></i>
                            با استفاده از API. این روش شما می توانید تمام با
                            بخشی از داده ها را با محدود کردن نتیجه دریافت کنید.
                            <br />

                            برای اطلاعات بیشتر به
                            <a class="text-underline">مستندات ارائه شده</a>
                            مراجعه کنید.
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-file-excel font-18 text-light-50 ml-10"
                            ></i>
                            دانلود کامل تمامی داده ها به صورت یک فایل excel که
                            به صورت روزانه آپدیت می شود. این فایل تا زمانی که
                            تمامی داده ها جمع آوری نشود به روز رسانی نمی شود اما
                            راه توصیه شده است زیرا فشار پردازش بر سرور را کم
                            کرده و امکان دریافت سریع تر آن در فرمت فشرده وجود
                            دارد.
                            <br />

                            برای اطلاعات بیشتر به
                            <a class="text-underline">مستندات ارائه شده</a>
                            مراجعه کنید.
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-file-spreadsheet font-18 text-light-50 ml-10"
                            ></i>
                            در صورتی که تمامی داده ها را یک بار دریافت کرده اید
                            یا به داده های روز خاصی نیاز دارید بهترین و سریع راه
                            دریافت داده های روزانه داده ها است. به راحتی
                            میتوانید این داده ها را با داده های دریافت شده ی
                            قبلی ادغام کرده و به پردازش آن ها بپردازید.

                            <br />
                            برای اطلاعات بیشتر به
                            <a class="text-underline">مستندات ارائه شده</a>
                            مراجعه کنید.
                          </li>
                        </ul>
                      </div>
                      <div
                        id="list-laboratory"
                        aria-labelledby="list-laboratory-list"
                        class="tab-pane fade"
                        role="tabpanel"
                      >
                        <h5>
                          <i
                            class="fad fa-vials font-18 text-light-50 ml-10"
                          ></i>
                          آزمایشگاه
                        </h5>
                        <br />
                        <p>
                          با توجه به هدف و روحیات هر شخص می توان استراتژی مالی
                          متفاوتی تعریف کرد.
                          <br />
                          یکی از راهکار های مناسب جهت راستی آزمایی استراتژی های
                          معاملاتی استفاده از روش های سیستمی و آزمایشگاهی است
                          زیرا:
                        </p>
                        <ul class="list-group list-group-flush text-right mt-4">
                          <li class="list-group-item">
                            <i
                              class="fad fa-meh font-18 text-light-50 ml-10"
                            ></i>
                            تاثیر احساسات را در معامله از بین می برد
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-robot font-18 text-light-50 ml-10"
                            ></i>
                            می تواند به صورت خودکار انجام شود
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-hard-hat font-18 text-light-50 ml-10"
                            ></i>
                            در محیط بدون خطر اجرا شده و در صورت نا کارامد بودن
                            باعث خسارت نمی شود
                          </li>
                        </ul>
                        <br />
                        به طور کلی دو راه برای شبیه سازی وجود دارد:
                        <br />
                        <br />
                        <span class="d-block h5">
                          <i
                            class="fad fa-stopwatch font-18 text-light-50 ml-10"
                          ></i>
                          پیپر تریدینگ
                        </span>
                        این روش در محیط واقعی و بلا درنگ اجرا می شود. در نتیجه
                        با وجود واقعی تر بودن نتایج اجرای این روش بسیار زمان بر
                        خواد بود.

                        <br />
                        <br />
                        <span class="d-block h5">
                          <i
                            class="fad fa-history font-18 text-light-50 ml-10"
                          ></i>
                          بک ‌تست
                        </span>
                        این روش مبتنی بر داده های تاریخی سهام است و با استفاده
                        از داده های موجود سهام اقدام به آزمایش استراتژی برنامه
                        ریزی شده می کند. مزیت بزرگ این روش سرعت بسیار زیاده
                        تحلیل آن است زیرا تمام داده های تست و آموزش از قبل موجود
                        است.

                        <br />
                        <br />
                        <br />
                        <span class="d-block h5">
                          <i
                            class="fad fa-star font-18 text-light-50 ml-10"
                          ></i>
                          امکانات ارائه شده
                        </span>
                        افزونه بورس تهران سعی بر آن دارد که روش بک تست را هرچه
                        بیشتر به واقعیت نزدیک کند. مسائل در نظر گرفته شده در
                        بستر ارائه شده:

                        <ul class="list-group list-group-flush text-right mt-4">
                          <li class="list-group-item">
                            <i
                              class="fad fa-warehouse-alt font-18 text-light-50 ml-10"
                            ></i>
                            با توجه به نقد شوندی سهم همیشه امکان خرید و فروش
                            وجود ندارد در نتیجه داده های عرضه و تقاضا در نظر
                            گرفته می شود.
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-calendar-alt font-18 text-light-50 ml-10"
                            ></i>
                            امکان اجرای کد در بازه روزانه (ساعت بازار) و ماهانه
                            (قیمت پایانی روز) وجود دارد.
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-passport font-18 text-light-50 ml-10"
                            ></i>
                            امکان اجرا بر روی داده های واقعی سهم های داخلی در
                            بازه ی زمان انتخابی وجود دارد.
                          </li>
                        </ul>

                        <div class="text-center">
                          <a class="btn btn-primary mt-30">ورود به آزمایشگاه</a>
                        </div>
                      </div>
                      <div
                        id="list-settings"
                        aria-labelledby="list-settings-list"
                        class="tab-pane fade"
                        role="tabpanel"
                      >
                        <h5>
                          <i
                            class="fad fa-handshake-alt font-18 text-light-50 ml-10"
                          ></i>
                          مشارکت
                        </h5>
                        <br />
                        <p>
                          پیاده سازی ایده های اقتصادی سختی های بسیار زیادی را
                          طلب می کند، از جمله:
                        </p>

                        <ul class="list-group list-group-flush text-right mt-4">
                          <li class="list-group-item">
                            <i
                              class="fad fa-car-bump font-18 text-light-50 ml-10"
                            ></i>
                            دریافت داده های منظم از سایت های مهم جهت تصمیم گیری
                            هوشمند و خودکار مانند tsetmc.
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-tachometer-alt-fast font-18 text-light-50 ml-10"
                            ></i>
                            طراحی بستری یکپارچه و پایدار جهت اجرای الگوریتم و
                            محاسبه ی اندیکاتور ها و دیگر محاسبات.
                          </li>
                          <li class="list-group-item">
                            <i
                              class="fad fa-tasks-alt font-18 text-light-50 ml-10"
                            ></i>
                            باز نویسی الگوریتم طراحی شده به یک زبان برنامه نویسی
                            واحد جهت یک پارچه سازی تمامی فرایند های اجرا و
                            مدیریت اجرای کد ها.
                          </li>
                        </ul>
                        <br />
                        <br />
                        <i class="fad fa-magic font-18 text-light-50 ml-10"></i>
                        در یک طرح مشارکتی در صورتی که الگوریتم ارائه شده توسط
                        شما به یک خروجی معقول از بخش آزمایش دست پیدا کند بعد از
                        بازبینی و ارزیابی آن ما تمامی مشکلات اجرای آن را برای
                        شما هموار کرده و امکان مدیریت بر اجرای الگوریتم را به
                        سادگی برای شما فراهم می کنیم.

                        <br />
                        <br />
                        بعد از دست یافتن به نتیجه مطلوب و ثبت آن در سیستم با ما
                        در ارتباط باشید. مراحل ارتباط متعاقبا در همان صفحه به
                        شما اعلام خواهد شد.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceWiki",
};
</script>

<style scoped></style>
