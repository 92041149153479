import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/fonts/fontawesome/css/all.css";
import "./assets/fonts/iran-sans/css/fontiran.css";
import "./assets/style/vendors/vectormap/jquery-jvectormap-2.0.3.css";
import "./assets/style/vendors/jquery-toggles/css/toggles.css";
import "./assets/style/vendors/jquery-toggles/css/themes/toggles-light.css";
import "./assets/style/vendors/jquery-toast-plugin/dist/jquery.toast.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/scss/style.scss";

import "jquery/src/jquery.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/style/vendors/jquery-toggles/toggles.min.js";

import VueApexCharts from "vue3-apexcharts";

createApp(App).use(store).use(router).use(VueApexCharts).mount("#app");
