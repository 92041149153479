
import { defineComponent } from "vue";

export default defineComponent({
  name: "SlideNavbar",
  props: {},
  data() {
    return {
      resizeObserver: null as unknown as ResizeObserver,
      active: -1,
      active_top: 0,
      active_left: 0,
      active_height: 0,
      active_width: 0,
      items: [
        {
          name: "خانه",
          link: { name: "home" },
          class: ["fad", "fa-home"],
        },
        {
          name: "تکنیکال",
          link: { name: "tsetmc" },
          class: ["far", "fa-chart-bar"],
        },
        {
          name: "کدال",
          link: { name: "codal" },
          class: ["fad", "fa-abacus"],
        },
        {
          name: "آزمایشگاه",
          link: { name: "wiki" },
          class: ["fad", "fa-chart-bar"],
        },
        {
          name: "پشتیبانی",
          link: { name: "support" },
          class: ["fad", "fa-question"],
        },
      ],
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.itemSelected);
    this.resizeObserver.observe(this.$refs.navbarContainer as Element);
  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
  watch: {
    active(_, __) {
      this.itemSelected();
    },
  },
  methods: {
    itemSelected() {
      if (this.$refs.navbar === null) return;

      let activeObj;
      if (this.active == -1)
        activeObj = (this.$refs.navbar as any[]).find((e) =>
          e.classList.contains("active")
        );
      else activeObj = (this.$refs.navbar as any[])[this.active];

      if (activeObj === null) return;

      this.active_top = activeObj.offsetTop;
      this.active_left = activeObj.offsetLeft;
      this.active_width = activeObj.clientWidth;
      this.active_height = activeObj.clientHeight;
    },
  },
});
