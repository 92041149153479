<template>
  <div class="dropdown">
    <div
      :class="{
        'wrapper-enable': !disableInput,
      }"
      class="wrapper"
      style="cursor: text"
    >
      <div class="row">
        <div
          class="col-12 my-3 d-flex align-items-center justify-content-center"
        >
          <input
            v-model.trim="value"
            :disabled="disableInput"
            :placeholder="placeholder"
            class="dropdown-input p-0 px-3"
            type="text"
          />
          <!-- @focus="inputFocused = true"
          @blur="inputFocused = false" -->
          <transition mode="out-in" name="fade">
            <loader v-if="disableInput" :color="color" size="20px"></loader>
            <span v-else class="d-flex">
              <i
                :class="{
                  disable: selectedItem === -1,
                  enable: selectedItem !== -1,
                }"
                class="fas fa-search px-3"
              ></i>
            </span>
          </transition>
        </div>
        <div class="col-12">
          <transition name="fade">
            <div v-if="filterIndex.length > 0" class="dropdown-list">
              <table
                class="table table-no-bordered table-hover table-striped mb-0 pb-0"
              >
                <tbody>
                  <tr
                    v-for="item in filterIndex"
                    :key="dataSource[item].id"
                    @click="selectedItem = dataSource[item].id"
                    style="cursor: pointer"
                  >
                    <td
                      v-for="(value, name) in dataSource[item].content"
                      :key="value + name"
                    >
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "vue-spinner/src/MoonLoader.vue";

export default {
  name: "DropdownList",
  props: {
    placeholder: String,
    keys: Array,
  },
  components: {
    Loader,
  },
  computed: {
    disableInput() {
      return this.suggest && this.dataSource.length === 0;
    },
  },
  methods: {
    filter() {
      this.filterIndex.splice(0);
      const currentInput = this.value.toLowerCase();
      if (currentInput.length === 0) {
        return;
      }

      for (const [i, item] of this.dataSource.entries()) {
        for (var name in item.content) {
          if (this.keys.findIndex((key) => key === name) === -1) {
            continue;
          }

          var currentName = item.content[name];
          if (
            typeof currentName === "string" ||
            currentName instanceof String
          ) {
            currentName = currentName.toLowerCase();
          }

          if (currentName.includes(currentInput)) {
            this.filterIndex.push(i);
          }
        }

        if (this.filterIndex.length > 2) {
          return;
        }
      }
    },
  },
  watch: {
    value(val) {
      this.filter();
    },
    dataSource(val) {
      this.filter();
    },
  },
  data() {
    return {
      inputFocused: false,
      suggest: true,
      selectedItem: -1,
      filterIndex: [],
      value: "",
      color: "#3282b8",
      dataSource: [
        {
          id: "48287330843249460",
          content: { symbol: "کارآفرینی", name: "گسترش کارآفرینی" },
        },
        {
          id: "64893209460264643",
          content: { symbol: "کالای پتروشیمی", name: "کالای پتروشیمی" },
        },
        {
          id: "8187498148443422",
          content: {
            symbol: "شماره گذاری کالا",
            name: "شماره گذاری کالا و خدمات",
          },
        },
        {
          id: "68923188291458394",
          content: { symbol: "توسعه فارس", name: "توسعه صادرات فارس" },
        },
        {
          id: "60783654574662426",
          content: { symbol: "وهنر", name: "گروه توسعه هنر ایران" },
        },
        {
          id: "47555909483877660",
          content: {
            symbol: "وهنر-پذیره",
            name: "گروه توسعه هنر ایران- پذیره",
          },
        },
        {
          id: "6257725767708176",
          content: { symbol: "تامکو", name: "تامین ماشین آلات ساختمانی نصب" },
        },
        {
          id: "67126881188552864",
          content: { symbol: "رمپنا", name: "گروه مپنا (سهامی عام)" },
        },
        {
          id: "33854964748757477",
          content: { symbol: "رنیک", name: "جنرال مکانیک" },
        },
        {
          id: "51459202425114449",
          content: { symbol: "تابا", name: "تابان نیرو سپاهان" },
        },
        {
          id: "3823243780502959",
          content: { symbol: "رتکو", name: "کنترلخوردگیتکینکو" },
        },
        {
          id: "22308305646551497",
          content: { symbol: "تپولا", name: "مهندسی مرآت پولاد" },
        },
        {
          id: "23049019886587905",
          content: { symbol: "خفولا", name: "خدمات فنی فولاد یزد" },
        },
        {
          id: "17645323253056599",
          content: { symbol: "توربین جنوب", name: "تجهیزات صنعتی توربین جنوب" },
        },
        {
          id: "28062748588070885",
          content: { symbol: "مهندسی جوش", name: "مهندسی جوش ایران" },
        },
        {
          id: "27557812383301067",
          content: { symbol: "بهسازان صنایع", name: "بهسازان صنایع خاورمیانه" },
        },
        {
          id: "495499165983171",
          content: {
            symbol: "صنعت ساختمان",
            name: "گسترش فنآوریهای صنعت ساختمان",
          },
        },
        {
          id: "651564801186566",
          content: { symbol: "پیمانکاری نوین", name: "پیمانکاری انرژی نوین" },
        },
        {
          id: "18585106079638501",
          content: { symbol: "ساراتل", name: "ساختمانی و تاسیساتی ساراتل" },
        },
        {
          id: "14648214561235888",
          content: {
            symbol: "آبفا اصفهان",
            name: "م.م. طرح و تحقیقات آبفا اصفهان",
          },
        },
        {
          id: "43362635835198978",
          content: { symbol: "های وب", name: "داده گسترعصرنوین-های وب" },
        },
        {
          id: "69847139870135237",
          content: { symbol: "اوان", name: "مبین وان کیش" },
        },
        {
          id: "63830424809501048",
          content: { symbol: "اپرداز", name: "آتیه داده پرداز" },
        },
        {
          id: "14079693677610396",
          content: { symbol: "آسیاتک", name: "انتقال داده های آسیاتک" },
        },
        {
          id: "66315581735594751",
          content: { symbol: "توسن", name: "توسعه سامانه ی نرم افزاری نگین" },
        },
        {
          id: "55254206302462116",
          content: { symbol: "آپ", name: "آسان پرداخت پرشین" },
        },
        {
          id: "50002340308486819",
          content: { symbol: "تاپکیش", name: "تجارت الکترونیک پارسیان کیش" },
        },
        {
          id: "47749661205825616",
          content: { symbol: "سیستم", name: "همکاران سیستم" },
        },
        {
          id: "29590002988360984",
          content: { symbol: "سپیدار", name: "سپیدار سیستم آسیا" },
        },
        {
          id: "23838634016123354",
          content: { symbol: "مرقام", name: "ایرانارقام" },
        },
        {
          id: "40505767672724777",
          content: { symbol: "رانفور", name: "خدماتانفورماتیک" },
        },
        {
          id: "4247709727327181",
          content: { symbol: "مفاخر", name: "توسعه فناوری اطلاعات خوارزمی" },
        },
        {
          id: "68941822863885255",
          content: { symbol: "رافزا", name: "رایان هم افزا" },
        },
        {
          id: "59607545337891226",
          content: { symbol: "پرداخت", name: "به پرداخت ملت" },
        },
        {
          id: "52792903131341205",
          content: { symbol: "افرا", name: "افرانت" },
        },
        {
          id: "55201604487356053",
          content: { symbol: "فن آوا", name: "گروه فن آوا" },
        },
        {
          id: "41048299027409941",
          content: { symbol: "رتاپ", name: "تجارت الکترونیک پارسیان" },
        },
        {
          id: "27952969918967492",
          content: { symbol: "رکیش", name: "کارت اعتباری ایران کیش" },
        },
        {
          id: "71856634742001725",
          content: { symbol: "سپ", name: "پرداخت الکترونیک سامان کیش" },
        },
        {
          id: "65999092673039059",
          content: { symbol: "مداران", name: "دادهپردازیایران" },
        },
        {
          id: "31907674226378369",
          content: { symbol: "فن آوا کارت", name: "فن آوا کارت" },
        },
        {
          id: "49320769557672822",
          content: { symbol: "داده فن آوا", name: "داده پردازی فن آوا" },
        },
        {
          id: "60815618436835490",
          content: { symbol: "فناوا کارت", name: "فن آوا کارت" },
        },
        {
          id: "53361855643723578",
          content: { symbol: "انفورماتیک", name: "گسترش انفورماتیک ایران" },
        },
        {
          id: "19310456400689867",
          content: { symbol: "خبازرس", name: "بازرسی مهندسی و صنعتی ایران" },
        },
        {
          id: "58741071099161284",
          content: { symbol: "فرابورس", name: "فرابورس ایران" },
        },
        {
          id: "44549439964296944",
          content: { symbol: "کالا", name: "بورس کالای ایران" },
        },
        {
          id: "60523145697836739",
          content: { symbol: "بورس", name: "بورس اوراق بهادار تهران" },
        },
        {
          id: "11129387075131725",
          content: { symbol: "تملت", name: "تامین سرمایه بانک ملت" },
        },
        {
          id: "18599703143458101",
          content: { symbol: "امید", name: "تامین سرمایه امید" },
        },
        {
          id: "58873907630765023",
          content: { symbol: "امین", name: "تامین سرمایه امین" },
        },
        {
          id: "49641108336531623",
          content: { symbol: "انرژی3", name: "سایراشخاص بورس انرژی" },
        },
        {
          id: "59142194115401696",
          content: { symbol: "لوتوس", name: "تامین سرمایه لوتوس پارسیان" },
        },
        {
          id: "25357135030606405",
          content: { symbol: "تنوین", name: "تامین سرمایه نوین" },
        },
        {
          id: "42256988946157416",
          content: { symbol: "تماوند", name: "تامین سرمایه دماوند" },
        },
        {
          id: "49869693814643443",
          content: { symbol: "اکالا", name: "کارگزاران بورس کالای ایران" },
        },
        {
          id: "60095061789823130",
          content: { symbol: "نبورس", name: "نهادهای مالی بورس اوراق بهادار" },
        },
        {
          id: "37366608481858080",
          content: { symbol: "کبورس", name: "کارگزاران بورس اوراق بهادار" },
        },
        {
          id: "10919655792568926",
          content: { symbol: "نکالا", name: "نهادهای مالی بورس کالای ایران" },
        },
        {
          id: "67612261115225625",
          content: { symbol: "تکالا", name: "تولیدکنندگان بورس کالای ایران" },
        },
        {
          id: "61725225143026283",
          content: { symbol: "انرژی1", name: "نهادهای مالی بورس انرژی" },
        },
        {
          id: "63531021766590755",
          content: { symbol: "سپرده", name: "سپرده گذاری مرکزی و تسویه وجوه" },
        },
        {
          id: "16330743198286823",
          content: { symbol: "انرژی2", name: "فعالان بورس انرژی" },
        },
        {
          id: "50374488794681480",
          content: { symbol: "تماوند", name: "تامین سرمایه دماوند-پذیره" },
        },
        {
          id: "68215656200943332",
          content: { symbol: "تامین سپهر", name: "تامین سرمایه سپهر" },
        },
        {
          id: "63679592178109910",
          content: { symbol: "تمدن", name: "تامین سرمایه تمدن" },
        },
        {
          id: "68980134713666314",
          content: {
            symbol: "سپرده گذاری",
            name: "سپرده گذاری مرکزی اوراق بهادار",
          },
        },
        {
          id: "40348118513018291",
          content: { symbol: "اراد50", name: "مرابحه عام دولت5-شرایط خاص0302" },
        },
        {
          id: "42911811024354777",
          content: { symbol: "اراد61", name: "مرابحه عام دولت61-ش.خاص0309" },
        },
        {
          id: "23257748575526845",
          content: { symbol: "اراد72", name: "مرابحه عام دولت72-ش.خاص0311" },
        },
        {
          id: "49223496739728165",
          content: { symbol: "اراد45", name: "مرابحه عام دولت4-شرایط خاص0107" },
        },
        {
          id: "48020123520149025",
          content: { symbol: "اراد46", name: "مرابحه عام دولت4-شرایط خاص0302" },
        },
        {
          id: "34933520304408694",
          content: { symbol: "اراد42", name: "مرابحه عام دولت4-شرایط خاص0106" },
        },
        {
          id: "45042869458982253",
          content: { symbol: "افاد52", name: "منفعت دولت5-شرایط خاص سپهر" },
        },
        {
          id: "19346156681228707",
          content: { symbol: "اراد22", name: "مرابحه عام دولت2-ش.خاص لوتوس" },
        },
        {
          id: "47484909397760341",
          content: { symbol: "اراد40", name: "مرابحه عام دولت4-شرایط خاص0207" },
        },
        {
          id: "55942420731425948",
          content: { symbol: "اراد60", name: "مرابحه عام دولتی6-ش.خاص0210" },
        },
        {
          id: "58134872797540565",
          content: { symbol: "اراد44", name: "مرابحه عام دولت4-شرایط خاص0007" },
        },
        {
          id: "60547219224791830",
          content: { symbol: "اراد51", name: "مرابحه عام دولت5-شرایط خاص0010" },
        },
        {
          id: "42255763746648766",
          content: { symbol: "اراد35", name: "مرابحه عام دولت3-شرایط خاص0103" },
        },
        {
          id: "47883592226425713",
          content: { symbol: "اراد43", name: "مرابحه عام دولت4-شرایط خاص0205" },
        },
        {
          id: "6760432273898471",
          content: { symbol: "اراد62", name: "مرابحه عام دولت62-ش.خاص0309" },
        },
        {
          id: "7902182071079987",
          content: { symbol: "اشاد1", name: "مشارکت دولتی1-بودجه1396" },
        },
        {
          id: "10231600590694992",
          content: { symbol: "افاد72", name: "منفعت دولت7-شرایط خاص سپهر" },
        },
        {
          id: "33889280063931972",
          content: { symbol: "اشاد10", name: "مشارکت دولتی10-بودجه1396" },
        },
        {
          id: "60978516449331479",
          content: { symbol: "افاد82", name: "منفعت دولت8-شرایط خاص لوتوس" },
        },
        {
          id: "24371538373380305",
          content: { symbol: "اراد33", name: "مرابحه عام دولت3-شرایط خاص0303" },
        },
        {
          id: "42546700252452484",
          content: { symbol: "اراد36", name: "مرابحه عام دولت3-شرایط خاص0104" },
        },
        {
          id: "21522990643077548",
          content: { symbol: "افاد53", name: "منفعت دولت5-شرایط خاص کاریزما" },
        },
        {
          id: "29935695527853744",
          content: { symbol: "افاد51", name: "منفعت دولت5-شرایط خاص کاردان" },
        },
        {
          id: "6072844413711540",
          content: { symbol: "اراد54", name: "مرابحه عام دولت5-شرایط خاص0109" },
        },
        {
          id: "35447985071122568",
          content: { symbol: "اراد11", name: "مرابحه عام دولت1-ش.خاص لوتوس" },
        },
        {
          id: "29963964834392911",
          content: { symbol: "اراد24", name: "مرابحه عام دولت2-ش.خاص سایر" },
        },
        {
          id: "23737719554962015",
          content: { symbol: "اراد48", name: "مرابحه عام دولت4-شرایط خاص0009" },
        },
        {
          id: "43048496991450781",
          content: { symbol: "افاد54", name: "منفعت دولت5-شرایط خاص لوتوس" },
        },
        {
          id: "17941426325664429",
          content: { symbol: "افاد4", name: "منفعت دولتی4-شرایط خاص14010729" },
        },
        {
          id: "51711424006096969",
          content: { symbol: "اراد47", name: "مرابحه عام دولت4-شرایط خاص0008" },
        },
        {
          id: "70884918776822518",
          content: { symbol: "اراد69", name: "مرابحه عام دولت69-ش.خاص0310" },
        },
        {
          id: "71417521805138182",
          content: { symbol: "اراد65", name: "مرابحه عام دولتی65-ش.خاص0210" },
        },
        {
          id: "37278604854336035",
          content: { symbol: "اشاد2", name: "مشارکت دولتی2-بودجه1396" },
        },
        {
          id: "53915437733679761",
          content: { symbol: "اراد82", name: "مرابحه عام دولت82-ش.خاص0109" },
        },
        {
          id: "20082736855988443",
          content: { symbol: "اراد53", name: "مرابحه عام دولت5-شرایط خاص0207" },
        },
        {
          id: "27319652872658388",
          content: { symbol: "اراد55", name: "مرابحه عام دولت5-شرایط خاص0209" },
        },
        {
          id: "50298041107397493",
          content: { symbol: "اراد49", name: "مرابحه عام دولت4-شرایط خاص0206" },
        },
        {
          id: "15783832138614569",
          content: { symbol: "افاد61", name: "منفعت دولت6-شرایط خاص140109" },
        },
        {
          id: "68902730683412061",
          content: { symbol: "افاد84", name: "منفعت دولت8-شرایط خاص سایر" },
        },
        {
          id: "61704081214633396",
          content: { symbol: "گندم2", name: "مرابحه گندم2-واجدشرایط خاص1400" },
        },
        {
          id: "46203760288181283",
          content: { symbol: "اراد84", name: "مرابحه عام دولت84-ش.خاص0306" },
        },
        {
          id: "13891373690607361",
          content: { symbol: "سلامت6", name: "مرابحه سلامت6واجدشرایط خاص1400" },
        },
        {
          id: "65367224352535772",
          content: { symbol: "اراد73", name: "مرابحه عام دولت73-ش.خاص0402" },
        },
        {
          id: "22164355468450959",
          content: { symbol: "اشاد9", name: "مشارکت دولتی9-بانکی-بودجه1395" },
        },
        {
          id: "66687959964607107",
          content: { symbol: "اراد74", name: "مرابحه عام دولت74-ش.خاص0402" },
        },
        {
          id: "25685504838927055",
          content: { symbol: "افاد74", name: "منفعت دولت7-شرایط خاص سایر" },
        },
        {
          id: "26805086797073180",
          content: { symbol: "اراد77", name: "مرابحه عام دولت77-ش.خاص0008" },
        },
        {
          id: "24472556975056129",
          content: { symbol: "اراد41", name: "مرابحه عام دولت4-شرایط خاص0006" },
        },
        {
          id: "57743663132185249",
          content: { symbol: "اراد38", name: "مرابحه عام دولت3-شرایط خاص0105" },
        },
        {
          id: "68429446337737591",
          content: { symbol: "اراد87", name: "مرابحه عام دولت87-ش.خاص0303" },
        },
        {
          id: "20069240924792138",
          content: { symbol: "اراد70", name: "مرابحه عام دولت70-ش.خاص0112" },
        },
        {
          id: "66130788425543684",
          content: { symbol: "اراد37", name: "مرابحه عام دولت3-شرایط خاص0005" },
        },
        {
          id: "52673792913945555",
          content: { symbol: "افاد55", name: "منفعت دولت5-شرایط خاص سایر" },
        },
        {
          id: "57474654408676379",
          content: { symbol: "اراد56", name: "مرابحه عام دولت5-شرایط خاص0308" },
        },
        {
          id: "64536596666647539",
          content: { symbol: "اراد90", name: "مرابحه عام دولت90-ش.خاص0502" },
        },
        {
          id: "16303324355390262",
          content: { symbol: "افاد1", name: "منفعت دولت-با شرایط خاص140006" },
        },
        {
          id: "3997755616402545",
          content: { symbol: "افاد73", name: "منفعت دولت7-شرایط خاص نوین" },
        },
        {
          id: "37335595969902658",
          content: { symbol: "افاد2", name: "منفعت دولتی2-شرایط خاص14000626" },
        },
        {
          id: "39610317810712358",
          content: { symbol: "اراد78", name: "مرابحه عام دولت78-ش.خاص0011" },
        },
        {
          id: "50535512872751713",
          content: { symbol: "اراد58", name: "مرابحه عام دولت5-شرایط خاص0110" },
        },
        {
          id: "39268464225075616",
          content: { symbol: "اراد79", name: "مرابحه عام دولت79-ش.خاص0106" },
        },
        {
          id: "35000222122072953",
          content: { symbol: "اراد76", name: "مرابحه عام دولت76-ش.خاص0304" },
        },
        {
          id: "44902394582885206",
          content: { symbol: "اراد83", name: "مرابحه عام دولت83-ش.خاص0412" },
        },
        {
          id: "34058131978311520",
          content: { symbol: "اراد64", name: "مرابحه عام دولتی64-ش.خاص0111" },
        },
        {
          id: "48004422159326848",
          content: { symbol: "اراد59", name: "مرابحه عام دولتی5-ش.خاص0309" },
        },
        {
          id: "53462518979307111",
          content: { symbol: "اراد68", name: "مرابحه عام دولت68-ش.خاص0310" },
        },
        {
          id: "23358076115432411",
          content: { symbol: "افاد62", name: "منفعت دولت6-شرایط خاص ملت" },
        },
        {
          id: "19024886606470752",
          content: { symbol: "اجاد42", name: "اجاره دولت-واجدشرایط خاص991224" },
        },
        {
          id: "16368754219878684",
          content: { symbol: "اراد31", name: "مرابحه عام دولت3-شرایط خاص0208" },
        },
        {
          id: "68109756920100427",
          content: { symbol: "اراد66", name: "مرابحه عام دولت66-ش.خاص0310" },
        },
        {
          id: "49040900499589053",
          content: { symbol: "اجاد3", name: "اجاره دولت-واجدشرایط خاص991118" },
        },
        {
          id: "71455937258130874",
          content: { symbol: "اراد86", name: "مرابحه عام دولت86-ش.خاص0204" },
        },
        {
          id: "34185897261926724",
          content: { symbol: "اراد57", name: "مرابحه عام دولت5-شرایط خاص0309" },
        },
        {
          id: "61769980139345346",
          content: { symbol: "اراد67", name: "مرابحه عام دولت67-ش.خاص0310" },
        },
        {
          id: "20536030960229486",
          content: { symbol: "اراد63", name: "مرابحه عام دولت63-ش.خاص0309" },
        },
        {
          id: "10455424349923115",
          content: { symbol: "اراد32", name: "مرابحه عام دولت3-شرایط خاص0211" },
        },
        {
          id: "33003065739216642",
          content: { symbol: "اراد88", name: "مرابحه عام دولت88-ش.خاص0411" },
        },
        {
          id: "11800678942469896",
          content: { symbol: "اراد89", name: "مرابحه عام دولت89-ش.خاص0411" },
        },
        {
          id: "51598612965038612",
          content: { symbol: "افاد81", name: "منفعت دولت8-شرایط خاص مهر" },
        },
        {
          id: "42728405194317370",
          content: { symbol: "اراد34", name: "مرابحه عام دولت3-شرایط خاص0305" },
        },
        {
          id: "51298991395782823",
          content: { symbol: "اراد85", name: "مرابحه عام دولت85-ش.خاص0107" },
        },
        {
          id: "41997809087904044",
          content: { symbol: "اراد71", name: "مرابحه عام دولت71-ش.خاص0311" },
        },
        {
          id: "41917042813192905",
          content: { symbol: "اراد75", name: "مرابحه عام دولت75-ش.خاص0402" },
        },
        {
          id: "50792786683910016",
          content: { symbol: "کرمان", name: "س. توسعه وعمران استان کرمان" },
        },
        {
          id: "63481599728522324",
          content: { symbol: "ثشاهد", name: "سرمایه گذاری شاهد" },
        },
        {
          id: "49953653111442595",
          content: { symbol: "کیسون", name: "شرکت کیسون" },
        },
        {
          id: "17800036702302776",
          content: { symbol: "ثاخت", name: "بینالمللیتوسعهساختمان" },
        },
        {
          id: "16693610252404739",
          content: { symbol: "ثالوند", name: "سرمایه گذاری مسکن الوند" },
        },
        {
          id: "32845891587040106",
          content: { symbol: "ثنوسا", name: "نوسازیوساختمانتهران" },
        },
        {
          id: "20926459161497908",
          content: { symbol: "ثتران", name: "سرمایه گذاری مسکن تهران" },
        },
        {
          id: "14744445176220774",
          content: { symbol: "ثبهساز", name: "بهساز کاشانه تهران" },
        },
        {
          id: "1358190916156744",
          content: { symbol: "وآذر", name: "سرمایهگذاریتوسعهآذربایجان" },
        },
        {
          id: "17617474823279712",
          content: { symbol: "آ س پ", name: "آ.س.پ" },
        },
        {
          id: "18883380772506226",
          content: { symbol: "ثرود", name: "سرمایهگذاری مسکن زاینده رود" },
        },
        {
          id: "18568733593280948",
          content: { symbol: "ثامید", name: "توسعه و عمران امید" },
        },
        {
          id: "25514780181345713",
          content: { symbol: "وساخت", name: "سرمایه گذاری ساختمان ایران" },
        },
        {
          id: "68117765376081366",
          content: { symbol: "وتوس", name: "توسعهشهریتوسگستر" },
        },
        {
          id: "30852391633490755",
          content: { symbol: "ثفارس", name: "عمرانوتوسعهفارس" },
        },
        {
          id: "63315013743060811",
          content: { symbol: "ثنور", name: "سرمایه گذاری کوه نور" },
        },
        {
          id: "53999651992586159",
          content: { symbol: "ثپردیس", name: "سرمایه گذاری مسکن پردیس" },
        },
        {
          id: "47702059190622416",
          content: { symbol: "ثتوسا", name: "س.توسعه و عمران استان اردبیل" },
        },
        {
          id: "3863538898378476",
          content: { symbol: "ثمسکن", name: "سرمایهگذاری مسکن" },
        },
        {
          id: "66456062140680461",
          content: { symbol: "ثامان", name: "سامان گستراصفهان" },
        },
        {
          id: "68909035712962732",
          content: { symbol: "ثباغ", name: "شهرسازی و خانه سازی باغمیشه" },
        },
        {
          id: "59612098290740355",
          content: { symbol: "ثاباد", name: "توریستی ورفاهی آبادگران ایران" },
        },
        {
          id: "64707090254488560",
          content: { symbol: "ثعتما", name: "س. ساختمانی اعتماد گستر" },
        },
        {
          id: "15726796686853780",
          content: { symbol: "ثعمرا", name: "شرکت عمران و توسعه شاهد" },
        },
        {
          id: "17939384202383793",
          content: { symbol: "ثغرب", name: "شرکت سرمایه گذاری مسکن شمالغرب" },
        },
        {
          id: "40043919653526083",
          content: { symbol: "وثخوز", name: "سرمایه گذاری و توسعه خوزستان" },
        },
        {
          id: "6043384171800349",
          content: { symbol: "ثشرق", name: "سرمایه گذاری مسکن شمال شرق" },
        },
        {
          id: "28854105556435129",
          content: { symbol: "ثزاگرس", name: "سرمایه گذاری توسعه عمران زاگرس" },
        },
        {
          id: "7503669593172728",
          content: { symbol: "ثاصفا", name: "شرکت ساختمان اصفهان" },
        },
        {
          id: "12965822877128721",
          content: { symbol: "ثقزوی", name: "شرکت عمران و سازندگی قزوین" },
        },
        {
          id: "30507152381699953",
          content: { symbol: "ثجوان", name: "تامین مسکن جوانان" },
        },
        {
          id: "831325835570803",
          content: { symbol: "ثنام", name: "س ساختمانی ب نام آوران مهندسی" },
        },
        {
          id: "40650252484299134",
          content: { symbol: "ثاژن", name: "سخت آژند" },
        },
        {
          id: "45066064863062755",
          content: { symbol: "ثنظام", name: "س. ساختمانی نظام مهندسی ایران" },
        },
        {
          id: "14009560662665347",
          content: { symbol: "غیرصنعتی", name: "خدمات غیر صنعتی گاز ایران" },
        },
        {
          id: "8403167990439117",
          content: { symbol: "ثملی", name: "بین المللی ساختمان و صنعت ملی" },
        },
        {
          id: "22229150167002259",
          content: { symbol: "واوان", name: "ساختمانی واوان" },
        },
        {
          id: "28096527110269576",
          content: {
            symbol: "مسکن ایران",
            name: "عمران و مسکن سازان ایران-یکجا",
          },
        },
        {
          id: "3576992542663284",
          content: { symbol: "ایجاد محیط", name: "ایجاد محیط" },
        },
        {
          id: "30317860209027430",
          content: { symbol: "سکارآفرین", name: "سرمایه گذاری بانک کارآفرین" },
        },
        {
          id: "33220705750260507",
          content: { symbol: "عمران شرق", name: "عمران و مسکنسازان منطقه شرق" },
        },
        {
          id: "41818019719206849",
          content: { symbol: "عمران تکلار", name: "ساختمانی عمران تکلار" },
        },
        {
          id: "48790611518003126",
          content: { symbol: "ثعمسا", name: "عمران و مسکن سازان ایران" },
        },
        {
          id: "50220277245668448",
          content: { symbol: "مپسا", name: "مدیریت پروژههای ساختمانی ایران" },
        },
        {
          id: "25832315372089844",
          content: { symbol: "بناآفرین", name: "گسترش سازه بناآفرین ایرانیان" },
        },
        {
          id: "18401418213049852",
          content: {
            symbol: "مسکن شمالغرب",
            name: "عمران و مسکن سازان شمالغرب",
          },
        },
        {
          id: "48460161182549846",
          content: { symbol: "صمسکن912", name: "ص دین مسکن جنوب-ماهانه16درصد" },
        },
        {
          id: "60887982279284651",
          content: { symbol: "ثجنوب", name: "سرمایه گذاری مسکن جنوب" },
        },
        {
          id: "67675656072510693",
          content: { symbol: "پالایش", name: "صندوق پالایشی یکم-سهام" },
        },
        {
          id: "62235397452612911",
          content: {
            symbol: "دارا یکم",
            name: "صندوق واسطه گری مالی یکم-سهام",
          },
        },
        {
          id: "64942549055019553",
          content: { symbol: "سرو", name: "صندوق س سروسودمند مدبران-سهام" },
        },
        {
          id: "33887145736684266",
          content: { symbol: "آگاس", name: "صندوق س.هستی بخش آگاه-س" },
        },
        {
          id: "11427939669935844",
          content: { symbol: "اطلس", name: "صندوق س.توسعه اندوخته آینده-س" },
        },
        {
          id: "65576885779918210",
          content: { symbol: "مدیر", name: "صندوق مدیریت ثروت ص.بازنشستگی" },
        },
        {
          id: "34718633636164421",
          content: { symbol: "کمند", name: "صندوق س. با درآمد ثابت کمند" },
        },
        {
          id: "53251602435454519",
          content: { symbol: "کیان", name: "صندوق س. با درآمد ثابت کیان" },
        },
        {
          id: "43443105991896600",
          content: { symbol: "ارزش", name: "صندوق س ارزش آفرین بیدار-سهام" },
        },
        {
          id: "45728383369147894",
          content: {
            symbol: "امین یکم",
            name: "صندوق درآمد ثابت امین یکم فردا",
          },
        },
        {
          id: "70595828753641750",
          content: { symbol: "پارند", name: "صندوق س. پارند پایدار سپهر" },
        },
        {
          id: "65023851436340574",
          content: { symbol: "کاردان", name: "صندوق س تجارت شاخصی کاردان" },
        },
        {
          id: "18007109712724189",
          content: { symbol: "آوا", name: "صندوق س. سهام آوای معیار" },
        },
        {
          id: "66818022341772870",
          content: { symbol: "اعتماد", name: "صندوق س.اعتماد آفرین پارسیان-د" },
        },
        {
          id: "2161110547458064",
          content: { symbol: "سپیدما", name: "صندوق س. سپید دماوند" },
        },
        {
          id: "3846143218462419",
          content: { symbol: "افران", name: "صندوق س افرا نماد پایدار-ثابت" },
        },
        {
          id: "69067576215760005",
          content: { symbol: "کاریس", name: "صندوق س.سپهر کاریزما-س" },
        },
        {
          id: "66036975502302203",
          content: { symbol: "فیروزه", name: "صندوق شاخص30 شرکت فیروزه- سهام" },
        },
        {
          id: "1438514795814416",
          content: { symbol: "یاقوت", name: "صندوق س یاقوت آگاه-ثابت" },
        },
        {
          id: "57761388729898548",
          content: { symbol: "اوصتا", name: "صندوق س. اندیشه ورزان صباتامین" },
        },
        {
          id: "16040900750729921",
          content: { symbol: "کامیاب", name: "صندوق س. کامیاب آشنا-د" },
        },
        {
          id: "15451317146134956",
          content: { symbol: "افق ملت", name: "صندوق س در سهام افق ملت" },
        },
        {
          id: "66682662312253625",
          content: { symbol: "آساس", name: "صندوق س.آسمان آرمانی سهام-س" },
        },
        {
          id: "41286608288791633",
          content: { symbol: "وبازار", name: "صندوق س شاخصی بازارآشنا" },
        },
        {
          id: "45205530868811305",
          content: { symbol: "صایند", name: "صندوق س. گنجینه آینده روشن-د" },
        },
        {
          id: "15494954332657697",
          content: { symbol: "همای", name: "صندوق س همای آگاه-ثابت" },
        },
        {
          id: "28788598290160782",
          content: { symbol: "الماس", name: "صندوق س.امین تدبیرگران فردا-س" },
        },
        {
          id: "16056283141617755",
          content: { symbol: "کارین", name: "صندوق س نگین سامان-ثابت" },
        },
        {
          id: "37222720235819361",
          content: { symbol: "بذر", name: "صندوق س بذر امید آفرین-سهام" },
        },
        {
          id: "22839330962768817",
          content: { symbol: "آتیمس", name: "صندوق س.آرمان آتیه درخشان مس-س" },
        },
        {
          id: "13666407494621646",
          content: { symbol: "فراز", name: "صندوق س.سهام فراز داریک" },
        },
        {
          id: "62012736978844991",
          content: { symbol: "دارا", name: "صندوق س. دارا الگوریتم-د" },
        },
        {
          id: "24869832924911721",
          content: { symbol: "آسامید", name: "صندوق س.مشترک آسمان امید" },
        },
        {
          id: "30282299500988269",
          content: { symbol: "آکورد", name: "صندوق س. آرمان آتی کوثر-د" },
        },
        {
          id: "50139638026536387",
          content: { symbol: "زرین", name: "صندوق س. سهام زرین کوروش" },
        },
        {
          id: "39453972158399542",
          content: { symbol: "سپاس", name: "صندوق س. پاداش سهامداری-ثابت" },
        },
        {
          id: "53419976284977130",
          content: { symbol: "تصمیم", name: "صندوق س با درآمد ثابت تصمیم" },
        },
        {
          id: "10145129193828624",
          content: { symbol: "نگین", name: "ص.س. زمین و ساختمان نگین شهرری" },
        },
        {
          id: "17226661368470120",
          content: { symbol: "سپر", name: "صندوق س سپر سرمایه بیدار- ثابت" },
        },
        {
          id: "47101579271117172",
          content: { symbol: "دمعیار", name: "صندوق س. توازن معیار" },
        },
        {
          id: "31366347648583654",
          content: { symbol: "ارمغان", name: "ص سرمایه گذاری ارمغان ایرانیان" },
        },
        {
          id: "71672399601682259",
          content: { symbol: "ثروتم", name: "صندوق س. ثروت آفرین پارسیان-س" },
        },
        {
          id: "10795723506538053",
          content: { symbol: "فیروزا", name: "صندوق بادرآمد ثابت فیروزه آسیا" },
        },
        {
          id: "65249046611427924",
          content: { symbol: "فردا", name: "صندوق س آوای فردای زاگرس-ثابت" },
        },
        {
          id: "59598536122397373",
          content: { symbol: "سخند", name: "صندوق س.سپهرخبرگان نفت-د" },
        },
        {
          id: "71076372178147339",
          content: { symbol: "داریک", name: "صندوق س. اعتماد داریک-د" },
        },
        {
          id: "36592972482259020",
          content: { symbol: "آسام", name: "صندوق س.آرمان سپهر آشنا-م" },
        },
        {
          id: "38713440086361985",
          content: { symbol: "ثهام", name: "صندوق س ثروت هامرز-سهام" },
        },
        {
          id: "68203878405672734",
          content: { symbol: "صنوین", name: "صندوق سرمایهگذاری صنوین-مختلط" },
        },
        {
          id: "61265100181977543",
          content: { symbol: "مانی", name: "صندوق س. با درآمد ثابت مانی" },
        },
        {
          id: "4395741730355818",
          content: { symbol: "استارز", name: "ص.س.جسورانه ستاره برتر" },
        },
        {
          id: "57728534324022361",
          content: { symbol: "گنجین", name: "صندوق س. گنجینه یکم آوید" },
        },
        {
          id: "63888998422355039",
          content: { symbol: "نوآور", name: "ص.س.خصوصی اعتبارسرمایه نوآفرین" },
        },
        {
          id: "15124889255100138",
          content: { symbol: "رویش", name: "ص.س.جسورانه رویش لوتوس" },
        },
        {
          id: "67522512921942106",
          content: { symbol: "پادا", name: "صندوق س.پاداش سرمایه پارس" },
        },
        {
          id: "18865325633315847",
          content: { symbol: "خاتم", name: "صندوق س خاتم ایساتیس پویا-ثابت" },
        },
        {
          id: "31913287805282551",
          content: { symbol: "سیناد", name: "صندوق س.سپهرسودمند سینا" },
        },
        {
          id: "27812005859539773",
          content: { symbol: "ثروت", name: "ص.س.خصوصی ثروت آفرین فیروزه" },
        },
        {
          id: "18919451747622263",
          content: { symbol: "ویسرو", name: "ص.س.جسورانه سرو رشد پایداریکم" },
        },
        {
          id: "58789178087946067",
          content: { symbol: "داریوش", name: "صندوق س. سهام ثروت داریوش" },
        },
        {
          id: "48970598895465763",
          content: { symbol: "پویا", name: "ص.س.جسورانه پویا الگوریتم" },
        },
        {
          id: "57585821705408565",
          content: { symbol: "ونچر", name: "ص.س.جسورانه فیروزه" },
        },
        {
          id: "15090491830692116",
          content: { symbol: "پارتین", name: "ص.س.جسورانه پارتیان" },
        },
        {
          id: "26780282166315918",
          content: { symbol: "ثبات", name: "صندوق س. درآمد ثابت ثبات ویستا" },
        },
        {
          id: "2696258159590598",
          content: { symbol: "پیشگام", name: "ص.س.جسورانه رشد یکم پیشگام" },
        },
        {
          id: "8603978909726038",
          content: { symbol: "آرمانی", name: "ص.س.جسورانه فناوری آرمانی" },
        },
        {
          id: "58965710006903807",
          content: { symbol: "کمان", name: "ص.س.خصوصی کمان کاریزما" },
        },
        {
          id: "28749125862783655",
          content: { symbol: "پیشرفت", name: "ص.س.جسورانه پیشرفت" },
        },
        {
          id: "22811176775480091",
          content: { symbol: "اخابر", name: "مخابرات ایران" },
        },
        {
          id: "68635710163497089",
          content: { symbol: "همراه", name: "شرکت ارتباطات سیار ایران" },
        },
        {
          id: "40601326015230157",
          content: {
            symbol: "صخابر102",
            name: "صکوک اجاره مخابرات-3 ماهه 16%",
          },
        },
        {
          id: "63563137542184264",
          content: { symbol: "موج تامین", name: "موج تامین ارتباطات پارس" },
        },
        {
          id: "51971068201094874",
          content: { symbol: "بپاس", name: "بیمه پاسارگاد" },
        },
        {
          id: "13611044044646901",
          content: { symbol: "ملت", name: "بیمه ملت" },
        },
        {
          id: "39751275523025334",
          content: { symbol: "اتکای", name: "بیمه اتکایی ایرانیان" },
        },
        {
          id: "43966385447049549",
          content: { symbol: "ودی", name: "بیمه دی" },
        },
        {
          id: "51106317433079213",
          content: { symbol: "آسیا", name: "بیمه آسیا" },
        },
        {
          id: "70270965300262393",
          content: { symbol: "البرز", name: "بیمه البرز" },
        },
        {
          id: "60079434631497942",
          content: { symbol: "ورازی", name: "بیمه رازی" },
        },
        {
          id: "48511238766369097",
          content: { symbol: "دانا", name: "بیمه دانا" },
        },
        {
          id: "22275596386264204",
          content: { symbol: "کوثر", name: "بیمه کوثر" },
        },
        {
          id: "54493234408301135",
          content: { symbol: "بنو", name: "بیمه تجارت نو" },
        },
        {
          id: "59866041653103343",
          content: { symbol: "نوین", name: "بیمه نوین" },
        },
        {
          id: "9481703061634967",
          content: { symbol: "پارسیان", name: "بیمه پارسیان" },
        },
        {
          id: "43291783149314349",
          content: { symbol: "وسرمد", name: "بیمه سرمد" },
        },
        {
          id: "5599691633622269",
          content: { symbol: "بخاور", name: "بیمه زندگی خاورمیانه" },
        },
        {
          id: "12777578088653944",
          content: { symbol: "وحکمت", name: "بیمه حکمت صبا" },
        },
        {
          id: "6847536925606808",
          content: { symbol: "ومعلم", name: "بیمه معلم" },
        },
        {
          id: "41625340598198551",
          content: { symbol: "بساما", name: "بیمه سامان" },
        },
        {
          id: "56591881518499520",
          content: { symbol: "وسین", name: "بیمه سینا" },
        },
        {
          id: "5128151910501174",
          content: { symbol: "وحافظ", name: "بیمه حافظ" },
        },
        { id: "29860265627578401", content: { symbol: "ما", name: "بیمه ما" } },
        {
          id: "27405735172634593",
          content: { symbol: "اتکام", name: "بیمه اتکایی امین" },
        },
        {
          id: "27361263980884014",
          content: { symbol: "وتعاون", name: "بیمه تعاون" },
        },
        {
          id: "19839750988634567",
          content: { symbol: "وآفری", name: "بیمه کارآفرین" },
        },
        {
          id: "26543014712914772",
          content: { symbol: "میهن", name: "بیمه میهن" },
        },
        {
          id: "38738476064699383",
          content: { symbol: "آرمان", name: "بیمه آرمان" },
        },
        {
          id: "26882843763780650",
          content: { symbol: "باران", name: "بیمه زندگی باران50%تادیه" },
        },
        {
          id: "51429911704474163",
          content: { symbol: "اتکاسا", name: "بیمه اتکایی سامان50% تادیه" },
        },
        {
          id: "46772701938567445",
          content: { symbol: "اتکام", name: "شرکت بیمه اتکایی امین" },
        },
        {
          id: "5661338086089059",
          content: { symbol: "وسرمد", name: "بیمه سرمد" },
        },
        {
          id: "46247216629420121",
          content: {
            symbol: "باران-پذیره",
            name: "بیمه زندگی باران50%تادیه-پذیره",
          },
        },
        {
          id: "55906836177528951",
          content: { symbol: "تعاون", name: "بیمه تعاون" },
        },
        {
          id: "21030786915898705",
          content: { symbol: "تجارت", name: "بیمه تجارت نو(50% پرداخت شده)" },
        },
        {
          id: "64375097205269506",
          content: { symbol: "آریان", name: "سرمایه گذاری پارس آریان" },
        },
        {
          id: "57498323844693360",
          content: { symbol: "ومعین", name: "سرمایه گذاری توسعه معین ملت" },
        },
        {
          id: "65041567577976981",
          content: { symbol: "ولامید", name: "شرکت لیزینگ امید" },
        },
        {
          id: "45502027787448135",
          content: { symbol: "وکاسپی", name: "اعتباری کاسپین" },
        },
        {
          id: "30399039934945809",
          content: { symbol: "وآوین", name: "سرمایه گذاری آوین" },
        },
        {
          id: "63407210152538984",
          content: { symbol: "توسعه صنعت", name: "سرمایه گذاری توسعه صنعت" },
        },
        {
          id: "6479253536750886",
          content: { symbol: "خاورمیانه", name: "بانک خاورمیانه" },
        },
        {
          id: "43356147887115191",
          content: { symbol: "پاسار-پذیره", name: "پذیره نویسی-بانک پاسارگاد" },
        },
        {
          id: "9609565131304189",
          content: { symbol: "ایران زمین", name: "بانک ایران زمین" },
        },
        {
          id: "5155664898275492",
          content: { symbol: "وکوثر", name: "مالی و اعتباری کوثر مرکزی-حذف" },
        },
        {
          id: "60610861509165508",
          content: { symbol: "حکشتی", name: "کشتیرانی جمهوری اسلامی ایران" },
        },
        {
          id: "44625249840480397",
          content: { symbol: "حآسا", name: "آسیا سیر ارس" },
        },
        {
          id: "22260326095996531",
          content: { symbol: "حتوکا", name: "حملونقلتوکا" },
        },
        {
          id: "5564768007356822",
          content: { symbol: "حریل", name: "ریل پردازسیر" },
        },
        {
          id: "35424116338766901",
          content: { symbol: "حفارس", name: "حمل و نقل بین المللی خلیج فارس" },
        },
        {
          id: "37389789764168256",
          content: { symbol: "توریل", name: "توکاریل" },
        },
        {
          id: "13281937213456378",
          content: { symbol: "حسیر", name: "ریل سیر کوثر" },
        },
        {
          id: "1625149423498289",
          content: { symbol: "حسینا", name: "توسعه خدمات دریایی وبندری سینا" },
        },
        {
          id: "16369313804633525",
          content: { symbol: "حپترو", name: "حمل و نقل پتروشیمی( سهامی عام" },
        },
        {
          id: "917857106093847",
          content: { symbol: "حپارسا", name: "توسعه حمل و نقل ریلی پارسیان" },
        },
        {
          id: "21432551703060846",
          content: { symbol: "حآفرین", name: "ریل پرداز نو آفرین" },
        },
        {
          id: "63704201144621295",
          content: { symbol: "حبندر", name: "دریایی و کشتیرانی خط دریابندر" },
        },
        {
          id: "53334304751609770",
          content: { symbol: "حگردش", name: "ریل گردش ایرانیان" },
        },
        {
          id: "3722699128879020",
          content: { symbol: "حتاید", name: "تایدواترخاورمیانه" },
        },
        {
          id: "50587892784913370",
          content: { symbol: "حشکوه", name: "دلیجان طلایی شکوه پارس" },
        },
        {
          id: "30443839313522574",
          content: { symbol: "حرهشا", name: "رهشاد سپاهان (سهامی عام" },
        },
        {
          id: "14659267774417019",
          content: { symbol: "حگردش-پذیره", name: "ریل گردش ایرانیان-پذیره" },
        },
        {
          id: "53120329791933060",
          content: { symbol: "حرآهن", name: "راه آهن حمل ونقل" },
        },
        {
          id: "62310858666404704",
          content: { symbol: "حرهور", name: "مجتمع ترابری رهنورد" },
        },
        {
          id: "9319633797644863",
          content: { symbol: "ریل گستر", name: "حمل و نقل ریلی گسترش" },
        },
        {
          id: "38769769028973184",
          content: { symbol: "ایرسوتر", name: "حمل و نقل ایران و روسیه" },
        },
        {
          id: "8303557992090046",
          content: { symbol: "قشم", name: "خدمات کشتیرانی ستاره قشم" },
        },
        {
          id: "47756003257788498",
          content: { symbol: "حگهر", name: "حمل و نقل گهرترابر سیرجان" },
        },
        {
          id: "31940133965609434",
          content: { symbol: "ریل پرداز", name: "ریل پرداز نوآفرین" },
        },
        {
          id: "28253678449273505",
          content: { symbol: "حخزر", name: "کشتیرانی دریای خزر" },
        },
        {
          id: "56798822689379375",
          content: { symbol: "حاریا", name: "کشتیرانی آریا" },
        },
        {
          id: "68033471168853004",
          content: { symbol: "حفجر", name: "کشتیرانی والفجر" },
        },
        {
          id: "796556604064374",
          content: { symbol: "حقشم", name: "دریایی و مهندسی کشتیرانی قشم" },
        },
        {
          id: "62163307327505842",
          content: { symbol: "تسه0006", name: "بانک مسکن" },
        },
        {
          id: "1775538989481366",
          content: { symbol: "تسه0005", name: "بانک مسکن" },
        },
        {
          id: "59816244739292046",
          content: { symbol: "تسه0003", name: "بانک مسکن" },
        },
        {
          id: "4901886287361309",
          content: { symbol: "تسه9908", name: "بانک مسکن" },
        },
        {
          id: "7863570390410066",
          content: { symbol: "تسه0001", name: "بانک مسکن" },
        },
        {
          id: "3370493881245014",
          content: { symbol: "تسه9811", name: "بانک مسکن" },
        },
        {
          id: "16164451491521441",
          content: { symbol: "تسه9912", name: "بانک مسکن" },
        },
        {
          id: "8905255747609110",
          content: { symbol: "تسه0002", name: "بانک مسکن" },
        },
        {
          id: "16984771612846489",
          content: { symbol: "تسه0004", name: "بانک مسکن" },
        },
        {
          id: "12089903491411130",
          content: { symbol: "تسه9808", name: "بانک مسکن" },
        },
        {
          id: "40118769556907353",
          content: { symbol: "تسه9903", name: "بانک مسکن" },
        },
        {
          id: "33407851615887009",
          content: { symbol: "تسه9911", name: "بانک مسکن" },
        },
        {
          id: "69882333653254165",
          content: { symbol: "تسه9910", name: "بانک مسکن" },
        },
        {
          id: "12950089213578221",
          content: { symbol: "تسه9909", name: "بانک مسکن" },
        },
        {
          id: "16406868012951979",
          content: { symbol: "تسه9902", name: "بانک مسکن" },
        },
        {
          id: "1778142796834142",
          content: { symbol: "تملی803", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "4312836939310355",
          content: { symbol: "تملی809", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "43700005955291201",
          content: { symbol: "تسه9907", name: "بانک مسکن" },
        },
        {
          id: "14027767200567014",
          content: { symbol: "تسه9906", name: "بانک مسکن" },
        },
        {
          id: "38436612777305077",
          content: { symbol: "تسه9807", name: "بانک مسکن" },
        },
        {
          id: "52011547716040734",
          content: { symbol: "تسه9809", name: "بانک مسکن" },
        },
        {
          id: "68404094821755424",
          content: { symbol: "تسه9901", name: "بانک مسکن" },
        },
        {
          id: "40907790180697910",
          content: { symbol: "تسه9812", name: "بانک مسکن" },
        },
        {
          id: "49856142659693686",
          content: { symbol: "تسه9905", name: "بانک مسکن" },
        },
        {
          id: "143187001116603",
          content: { symbol: "تملی612", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "52699075188861283",
          content: { symbol: "تسه9810", name: "بانک مسکن" },
        },
        {
          id: "28533890681853700",
          content: { symbol: "تملی806", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "56881993417852599",
          content: { symbol: "تملی703", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "32051979696170482",
          content: { symbol: "تسه9904", name: "بانک مسکن" },
        },
        {
          id: "3826561665587230",
          content: { symbol: "تملی706", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "2147129961448440",
          content: { symbol: "تملی712", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "1200391996585610",
          content: { symbol: "تملی710", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "69399924050316081",
          content: { symbol: "تملی701", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "48043180063638654",
          content: { symbol: "تملی709", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "50689220001642146",
          content: { symbol: "تملی805", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "58474277119959628",
          content: { symbol: "تملی708", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "65913670626064922",
          content: { symbol: "تملی705", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "53892173815383358",
          content: { symbol: "تملی702", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "60115610575552097",
          content: { symbol: "تملی801", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "53866595272348310",
          content: { symbol: "تملی807", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "26622634572348721",
          content: { symbol: "تملی804", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "43319345548600453",
          content: { symbol: "تملی802", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "32982652838367432",
          content: { symbol: "تملی704", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "17661113951261114",
          content: { symbol: "تملی711", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "61506473958237003",
          content: { symbol: "تملی707", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "20393717141867821",
          content: { symbol: "تسه9709", name: "بانک مسکن" },
        },
        {
          id: "22545149639511257",
          content: { symbol: "تملی808", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "19422860143693289",
          content: { symbol: "تسه9806", name: "بانک مسکن" },
        },
        {
          id: "24794536744903047",
          content: { symbol: "تسه9609", name: "بانک مسکن" },
        },
        {
          id: "11332953223903902",
          content: { symbol: "تسه9701", name: "بانک مسکن" },
        },
        {
          id: "40124428756742341",
          content: { symbol: "تملی611", name: "تسهیلات مسکن بانک ملی ایران" },
        },
        {
          id: "66017397299787592",
          content: { symbol: "تسه9804", name: "بانک مسکن" },
        },
        {
          id: "37998178264888078",
          content: { symbol: "تسه9607", name: "بانک مسکن" },
        },
        {
          id: "6710089299473179",
          content: { symbol: "تسه9805", name: "بانک مسکن" },
        },
        {
          id: "1831214548277647",
          content: { symbol: "تسه9803", name: "بانک مسکن" },
        },
        {
          id: "48338606859106961",
          content: { symbol: "تسه9612", name: "بانک مسکن" },
        },
        {
          id: "35188369797280638",
          content: { symbol: "تسه9711", name: "بانک مسکن" },
        },
        {
          id: "61062392807810466",
          content: { symbol: "تسه9707", name: "بانک مسکن" },
        },
        {
          id: "19920168236103182",
          content: { symbol: "تسه9610", name: "بانک مسکن" },
        },
        {
          id: "15221664222769132",
          content: { symbol: "تسه9801", name: "بانک مسکن" },
        },
        {
          id: "10574526790623372",
          content: { symbol: "تسه9710", name: "بانک مسکن" },
        },
        {
          id: "55848849021200650",
          content: { symbol: "تسه9611", name: "بانک مسکن" },
        },
        {
          id: "61355897526163116",
          content: { symbol: "تسه9712", name: "بانک مسکن" },
        },
        {
          id: "10831551130410639",
          content: { symbol: "تسه9608", name: "بانک مسکن" },
        },
        {
          id: "5657981336716226",
          content: { symbol: "تسه9802", name: "بانک مسکن" },
        },
        {
          id: "38166945723865485",
          content: { symbol: "تسه9708", name: "بانک مسکن" },
        },
        {
          id: "14098803458396918",
          content: { symbol: "تسه9101", name: "بانک مسکن" },
        },
        {
          id: "11214189898887899",
          content: { symbol: "تسه9112", name: "بانک مسکن" },
        },
        {
          id: "47245955682789926",
          content: { symbol: "تسه9602", name: "بانک مسکن" },
        },
        {
          id: "68151613203549690",
          content: { symbol: "تسه9601", name: "بانک مسکن" },
        },
        {
          id: "12338753854555828",
          content: { symbol: "تسه9110", name: "بانک مسکن" },
        },
        {
          id: "46305227678799659",
          content: { symbol: "تسه8912", name: "بانک مسکن" },
        },
        {
          id: "59463449825542330",
          content: { symbol: "تسه8911", name: "بانک مسکن" },
        },
        {
          id: "12364611634133528",
          content: { symbol: "تسه9011", name: "بانک مسکن" },
        },
        {
          id: "27221698970690062",
          content: { symbol: "تسه9005", name: "بانک مسکن" },
        },
        {
          id: "163301738200990",
          content: { symbol: "تسه9012", name: "بانک مسکن" },
        },
        {
          id: "50026314345467725",
          content: { symbol: "تسه8908", name: "بانک مسکن" },
        },
        {
          id: "17828928229984367",
          content: { symbol: "تسه9204", name: "بانک مسکن" },
        },
        {
          id: "66492965118417192",
          content: { symbol: "تسه9111", name: "بانک مسکن" },
        },
        {
          id: "48305002535402787",
          content: { symbol: "تسه9201", name: "بانک مسکن" },
        },
        {
          id: "37219684915629654",
          content: { symbol: "تسه9509", name: "بانک مسکن" },
        },
        {
          id: "52620240501253486",
          content: { symbol: "تسه9001", name: "بانک مسکن" },
        },
        {
          id: "57731325451718490",
          content: { symbol: "تسه8907", name: "بانک مسکن" },
        },
        {
          id: "1245925241417929",
          content: { symbol: "تسه9108", name: "بانک مسکن" },
        },
        {
          id: "61978257933030834",
          content: { symbol: "تسه9606", name: "بانک مسکن" },
        },
        {
          id: "7420013136569528",
          content: { symbol: "تسه9505", name: "بانک مسکن" },
        },
        {
          id: "44275397707148088",
          content: { symbol: "تسه9202", name: "بانک مسکن" },
        },
        {
          id: "1359959101711313",
          content: { symbol: "تسه9207", name: "بانک مسکن" },
        },
        {
          id: "21350214000597969",
          content: { symbol: "تسه9511", name: "بانک مسکن" },
        },
        {
          id: "48393054214913539",
          content: { symbol: "تسه9103", name: "بانک مسکن" },
        },
        {
          id: "21851863906501460",
          content: { symbol: "تسه9604", name: "بانک مسکن" },
        },
        {
          id: "8817369445940242",
          content: { symbol: "تسه9506", name: "بانک مسکن" },
        },
        {
          id: "30592968583865547",
          content: { symbol: "تسه9004", name: "بانک مسکن" },
        },
        {
          id: "27157296306913953",
          content: { symbol: "تسه9507", name: "بانک مسکن" },
        },
        {
          id: "59298826747161711",
          content: { symbol: "تسه9003", name: "بانک مسکن" },
        },
        {
          id: "68995806996389257",
          content: { symbol: "تسه9102", name: "بانک مسکن" },
        },
        {
          id: "42070382870377817",
          content: { symbol: "تسه9205", name: "بانک مسکن" },
        },
        {
          id: "60767592882123239",
          content: { symbol: "تسه9105", name: "بانک مسکن" },
        },
        {
          id: "71086267369556869",
          content: { symbol: "تسه9007", name: "بانک مسکن" },
        },
        {
          id: "25095385550006158",
          content: { symbol: "تسه9106", name: "بانک مسکن" },
        },
        {
          id: "16725382720692568",
          content: { symbol: "تسه9603", name: "بانک مسکن" },
        },
        {
          id: "31723276281575803",
          content: { symbol: "تسه9510", name: "بانک مسکن" },
        },
        {
          id: "61951543013892913",
          content: { symbol: "تسه9006", name: "بانک مسکن" },
        },
        {
          id: "43638198413087069",
          content: { symbol: "تسه8909", name: "بانک مسکن" },
        },
        {
          id: "5019865278498569",
          content: { symbol: "تسه9503", name: "بانک مسکن" },
        },
        {
          id: "9097515773407564",
          content: { symbol: "تسه9104", name: "بانک مسکن" },
        },
        {
          id: "69569067443305938",
          content: { symbol: "تسه9008", name: "بانک مسکن" },
        },
        {
          id: "38087994492605307",
          content: { symbol: "تسه9002", name: "بانک مسکن" },
        },
        {
          id: "40346507120206669",
          content: { symbol: "تسه9605", name: "بانک مسکن" },
        },
        {
          id: "30038978589192551",
          content: { symbol: "تسه9512", name: "بانک مسکن" },
        },
        {
          id: "33400647129589624",
          content: { symbol: "تسه9009", name: "بانک مسکن" },
        },
        {
          id: "39348926601774626",
          content: { symbol: "تسه9010", name: "بانک مسکن" },
        },
        {
          id: "17217689629682758",
          content: { symbol: "تسه9504", name: "بانک مسکن" },
        },
        {
          id: "14825024264732797",
          content: { symbol: "تسه9508", name: "بانک مسکن" },
        },
        {
          id: "37163551945096882",
          content: { symbol: "تسه9208", name: "بانک مسکن" },
        },
        {
          id: "65890979512650954",
          content: { symbol: "تسه9107", name: "بانک مسکن" },
        },
        {
          id: "69465793209205703",
          content: { symbol: "تسه9206", name: "بانک مسکن" },
        },
        {
          id: "64749875350678762",
          content: { symbol: "تسه9203", name: "بانک مسکن" },
        },
        {
          id: "48045086469637559",
          content: { symbol: "تسه9109", name: "بانک مسکن" },
        },
        { id: "44818950263583523", content: { symbol: "دی", name: "بانک دی" } },
        {
          id: "778253364357513",
          content: { symbol: "وبملت", name: "بانک ملت" },
        },
        {
          id: "63917421733088077",
          content: { symbol: "وتجارت", name: "بانک تجارت" },
        },
        {
          id: "28320293733348826",
          content: { symbol: "وبصادر", name: "بانک صادرات ایران" },
        },
        {
          id: "33293588228706998",
          content: { symbol: "وپارس", name: "بانکپارسیان" },
        },
        {
          id: "9536587154100457",
          content: { symbol: "وپاسار", name: "بانک پاسارگاد" },
        },
        {
          id: "47302318535715632",
          content: { symbol: "ونوین", name: "بانکاقتصادنوین" },
        },
        {
          id: "22087269603540841",
          content: { symbol: "وپست", name: "پست بانک ایران" },
        },
        {
          id: "45050389997905274",
          content: { symbol: "وسینا", name: "بانک سینا" },
        },
        {
          id: "38179358042686391",
          content: { symbol: "سامان", name: "بانک سامان" },
        },
        {
          id: "24644999329120295",
          content: { symbol: "وملل", name: "اعتباری ملل" },
        },
        {
          id: "43913530989262989",
          content: { symbol: "سمایه", name: "بانک سرمایه" },
        },
        {
          id: "41379697187196382",
          content: { symbol: "وشهر", name: "بانک شهر" },
        },
        {
          id: "46830341954511303",
          content: { symbol: "وآیند", name: "بانک آینده" },
        },
        {
          id: "47996917271187218",
          content: { symbol: "وکار", name: "بانک کارآفرین" },
        },
        {
          id: "47333458678352378",
          content: { symbol: "وخاور", name: "بانک خاورمیانه" },
        },
        {
          id: "19954896371640204",
          content: { symbol: "وزمین", name: "بانک ایران زمین" },
        },
        {
          id: "23175320865252772",
          content: { symbol: "وسالت", name: "بانک قرض الحسنه رسالت" },
        },
        {
          id: "7920014658832193",
          content: { symbol: "وگردش", name: "بانک گردشگری" },
        },
        {
          id: "7769065543658313",
          content: { symbol: "توسعه", name: "اعتباری توسعه" },
        },
        {
          id: "41036181221659834",
          content: { symbol: "وکوثر", name: "شرکت اعتباری کوثر مرکزی" },
        },
        {
          id: "49776615757150035",
          content: { symbol: "وانصار", name: "بانک انصار" },
        },
        {
          id: "7881099657101647",
          content: { symbol: "حکمت", name: "بانک حکمت ایرانیان" },
        },
        {
          id: "71665987033532474",
          content: { symbol: "ومهر", name: "بانک مهر اقتصاد" },
        },
        {
          id: "49396161442874149",
          content: { symbol: "وقوام", name: "بانک قوامین" },
        },
        {
          id: "20236748310756911",
          content: { symbol: "ورفاه", name: "بانک رفاه کارگران" },
        },
        {
          id: "70841787274602388",
          content: { symbol: "وثامن", name: "شرکت اعتباری ثامن" },
        },
        {
          id: "57424362806755583",
          content: { symbol: "ونور", name: "اعتباری نور" },
        },
        {
          id: "20626178773287666",
          content: { symbol: "وآیند", name: "بانک آینده" },
        },
        {
          id: "34186024944625724",
          content: { symbol: "حذف -آرین", name: "بانک الکترونیکی آرین" },
        },
        {
          id: "7395271748414592",
          content: { symbol: "وخارزم", name: "سرمایه گذاری خوارزمی" },
        },
        {
          id: "12490072956930435",
          content: { symbol: "مدیریت", name: "س. و خدمات مدیریت صند. ب کشوری" },
        },
        {
          id: "114312662654155",
          content: { symbol: "وسپهر", name: "سرمایه گذاری گروه سپهرصادرات" },
        },
        {
          id: "45392752356003555",
          content: { symbol: "صبا", name: "سرمایه گذاری صبا تامین" },
        },
        {
          id: "19348717261145458",
          content: { symbol: "وهامون", name: "سرمایه گذاری هامون صبا" },
        },
        {
          id: "2944500421562364",
          content: { symbol: "وتوصا", name: "گروه س توسعه صنعتی ایران" },
        },
        {
          id: "64341992373049080",
          content: { symbol: "سدبیر", name: "س. تدبیرگران فارس وخوزستان" },
        },
        {
          id: "30447901674051381",
          content: { symbol: "ومدیر", name: "گ.مدیریت ارزش سرمایه ص ب کشوری" },
        },
        {
          id: "64217006537761390",
          content: { symbol: "وسکرمان", name: "س.سهام عدالت استان کرمان" },
        },
        {
          id: "6506179926371994",
          content: { symbol: "آریان", name: "سرمایه گذاری پارس آریان" },
        },
        {
          id: "37614886280396031",
          content: { symbol: "وساپا", name: "سرمایهگذاری سایپا" },
        },
        {
          id: "18063426072758458",
          content: { symbol: "وبهمن", name: "سرمایهگذاریبهمن" },
        },
        {
          id: "24785665268004766",
          content: { symbol: "وپویا", name: "سرمایه گذاری پویا" },
        },
        {
          id: "57309221039930244",
          content: { symbol: "وصنعت", name: "سرمایه گذاری توسعه صنعت وتجارت" },
        },
        {
          id: "17284166795866794",
          content: { symbol: "واحیا", name: "م .صنایع و معادن احیاء سپاهان" },
        },
        {
          id: "65774725600261203",
          content: { symbol: "وساشرقی", name: "شرکت س استان آذربایجان شرقی" },
        },
        {
          id: "2328862017676109",
          content: { symbol: "وسپه", name: "سرمایهگذاری سپه" },
        },
        {
          id: "22490169030401337",
          content: { symbol: "وآوا", name: "سرمایه گذاری آوا نوین" },
        },
        {
          id: "35282121039020302",
          content: { symbol: "وساغربی", name: "شرکت س استان آذربایجان غربی" },
        },
        {
          id: "11258722998911897",
          content: { symbol: "وسکاب", name: "س.ص.بازنشستگی کارکنان بانکها" },
        },
        {
          id: "58964593134314938",
          content: { symbol: "ومهان", name: "گروه توسعه مالی مهر آیندگان" },
        },
        {
          id: "35948133957468680",
          content: { symbol: "سرچشمه", name: "سرمایه گذاری مس سرچشمه" },
        },
        {
          id: "59462881877083131",
          content: { symbol: "وسکرشا", name: "س.سهام عدالت استان کرمانشاه" },
        },
        {
          id: "11773403764702778",
          content: { symbol: "وبیمه", name: "سرمایه گذاری صنعت بیمه" },
        },
        {
          id: "11827297444577200",
          content: { symbol: "وسصفا", name: "شرکت س استان اصفهان" },
        },
        {
          id: "15039949673085566",
          content: { symbol: "پردیس", name: "سرمایه گذاری پردیس" },
        },
        {
          id: "43283802997035462",
          content: { symbol: "وسبحان", name: "سرمایه گذاری سبحان" },
        },
        {
          id: "43951910415124966",
          content: { symbol: "وگستر", name: "گسترش سرمایه گذاری ایرانیان" },
        },
        {
          id: "46982154647719707",
          content: { symbol: "وصنا", name: "گروهصنایعبهشهرایران" },
        },
        {
          id: "36773155987365094",
          content: { symbol: "اعتلا", name: "شرکت سرمایه گذاری اعتلاء البرز" },
        },
        {
          id: "64973252728260903",
          content: { symbol: "وکبهمن", name: "مدیریت سرمایه گذاری کوثربهمن" },
        },
        {
          id: "28328710198554144",
          content: { symbol: "وبوعلی", name: "سرمایهگذاریبوعلی" },
        },
        {
          id: "60633055620418060",
          content: { symbol: "فلات", name: "گروه صنایع معادن فلات ایرانیان" },
        },
        {
          id: "56717416662584054",
          content: { symbol: "وکادو", name: "تکادو" },
        },
        {
          id: "44986797317463049",
          content: { symbol: "وثنو", name: "سرمایه گذاری ساختمانی نوین" },
        },
        {
          id: "59839275647597021",
          content: { symbol: "وثوق", name: "سرمایه گذاری وثوق امین" },
        },
        {
          id: "24662567615903665",
          content: { symbol: "وسنا", name: "سرمایه گذاری نیروگاهی ایران" },
        },
        {
          id: "53686258677793038",
          content: { symbol: "وارس", name: "سرمایه گذاری ارس صبا" },
        },
        {
          id: "17528249960294496",
          content: { symbol: "وتوسم", name: "سرمایهگذاریتوسعهملی" },
        },
        {
          id: "35369183060321179",
          content: { symbol: "ومشان", name: "س. فنی و مهندسی مشانیر" },
        },
        {
          id: "53647874954005806",
          content: { symbol: "واحصا", name: "احیاء صنایع خراسان" },
        },
        {
          id: "61298636307861167",
          content: { symbol: "وبرق", name: "س.کارکنان صنعت برق زنجان وقزوی" },
        },
        {
          id: "33541897671561960",
          content: { symbol: "واتی", name: "سرمایه گذاری آتیه دماوند" },
        },
        {
          id: "47841327496247362",
          content: { symbol: "واعتبار", name: "سرمایه گذاری اعتبار ایران" },
        },
        {
          id: "24212636157410845",
          content: { symbol: "وآرین", name: "شرکت توسعه اقتصادی آرین" },
        },
        {
          id: "51185499879934793",
          content: { symbol: "وسرضوی", name: "س.سهام عدالت استان خراسان رضوی" },
        },
        {
          id: "50580753680043015",
          content: { symbol: "معیار", name: "سرمایه گذاری معیار صنعت پارس" },
        },
        {
          id: "55916539620839777",
          content: { symbol: "وسگیلا", name: "شرکت س استان گیلان" },
        },
        {
          id: "31959715133485440",
          content: { symbol: "ولقمان", name: "سرمایه گذاری لقمان" },
        },
        {
          id: "10055255678920880",
          content: { symbol: "وملت", name: "سرمایه گذاری ملت" },
        },
        {
          id: "65018804181564924",
          content: { symbol: "گوهران", name: "سرمایه گذاری توسعه گوهران امید" },
        },
        {
          id: "36995197800118822",
          content: { symbol: "سنوین", name: "سرمایه گذاری اقتصاد نوین" },
        },
        {
          id: "9141577977527107",
          content: { symbol: "وایرا", name: "سرمایه گذاری صنایع ایران" },
        },
        {
          id: "17269972595370241",
          content: { symbol: "وآتوس", name: "س.آرین توسکا قیمت اسمی 350ریال" },
        },
        {
          id: "9761381741308262",
          content: { symbol: "وشمال", name: "س. چشم انداز توسعه شمال" },
        },
        {
          id: "55850912428859273",
          content: { symbol: "وسقم", name: "شرکت س استان قم" },
        },
        {
          id: "57600064931636077",
          content: { symbol: "وجامی", name: "سرمایه گذاری جامی" },
        },
        {
          id: "22318795784160675",
          content: { symbol: "وسهمدا", name: "شرکت س استان همدان" },
        },
        {
          id: "5454781314262062",
          content: { symbol: "وسخوز", name: "شرکت س استان خوزستان" },
        },
        {
          id: "23843877872814145",
          content: { symbol: "وامین", name: "س. امین توان آفرین ساز" },
        },
        {
          id: "69472361926040823",
          content: { symbol: "ویسا", name: "سرمایه گذاری ایساتیس پویا" },
        },
        {
          id: "36857080203588624",
          content: { symbol: "وسزنجان", name: "شرکت س استان زنجان" },
        },
        {
          id: "58358543014279572",
          content: { symbol: "وسلرستا", name: "شرکت س استان لرستان" },
        },
        {
          id: "14880127004206268",
          content: { symbol: "وآداک", name: "صنعت و تجارت آداک" },
        },
        {
          id: "10191122735393627",
          content: { symbol: "وسخراج", name: "شرکت س استان خراسان جنوبی" },
        },
        {
          id: "37351190176977060",
          content: { symbol: "وسکرد", name: "شرکت س استان کردستان" },
        },
        {
          id: "71335098849021006",
          content: { symbol: "وسفارس", name: "شرکت س استان فارس" },
        },
        {
          id: "16370154440238855",
          content: { symbol: "وسیستا", name: "شرکت س استان سیستان وبلوچستان" },
        },
        {
          id: "46820237871803554",
          content: { symbol: "وسهرمز", name: "شرکت س استان هرمزگان" },
        },
        {
          id: "47362853625306007",
          content: { symbol: "وسخراش", name: "شرکت س استان خراسان شمالی" },
        },
        {
          id: "1277592884769853",
          content: { symbol: "وسگلستا", name: "شرکت س استان گلستان" },
        },
        {
          id: "63570910379296908",
          content: { symbol: "وسیزد", name: "شرکت س استان یزد" },
        },
        {
          id: "66424163876658304",
          content: { symbol: "ودانا", name: "سرمایه گذاری دانایان پارس" },
        },
        {
          id: "11358107932902023",
          content: { symbol: "وسمازن", name: "س.سهام عدالت استان مازندران" },
        },
        {
          id: "19511179561056679",
          content: { symbol: "وسمرکز", name: "شرکت س استان مرکزی" },
        },
        {
          id: "27148572013604038",
          content: { symbol: "وفتخار", name: "سرمایه گذاری افتخارسهام" },
        },
        {
          id: "44411078630612905",
          content: { symbol: "وسبوشهر", name: "شرکت س استان بوشهر" },
        },
        {
          id: "59652306881376415",
          content: { symbol: "وسیلام", name: "شرکت س استان ایلام" },
        },
        {
          id: "7768327419174840",
          content: { symbol: "وساربیل", name: "شرکت س استان اردبیل" },
        },
        {
          id: "68870496728320072",
          content: { symbol: "وسکهبو", name: "س.عدالت ا. کهگیلویه وبویراحمد" },
        },
        {
          id: "45991797190214892",
          content: { symbol: "وآفر", name: "سرمایه گذاری ارزش آفرینان" },
        },
        {
          id: "43002514582588115",
          content: { symbol: "سلیم", name: "سرمایه گذاری سلیم" },
        },
        {
          id: "57884321808046956",
          content: {
            symbol: "خدمات بازنشستگی",
            name: "خدمات مدیریت صندوق بازنشستگی",
          },
        },
        {
          id: "13393354143485328",
          content: { symbol: "وستهران", name: "شرکت س استان تهران" },
        },
        {
          id: "27216330574067832",
          content: { symbol: "وارزش", name: "ارزش آفرینان پاسارگاد" },
        },
        {
          id: "25701602342262768",
          content: { symbol: "مدیریت سبا", name: "نوآوران مدیریت سبا" },
        },
        {
          id: "12021113328712802",
          content: { symbol: "حافظ اعتماد", name: "سرمایه گذاری حافظ اعتماد" },
        },
        {
          id: "59399204564407321",
          content: { symbol: "سآذر", name: "سرمایه گذاری آذر" },
        },
        {
          id: "19902029193133981",
          content: { symbol: "صنایع امید", name: "توسعه صنایع معدنی امید" },
        },
        {
          id: "63348046600252401",
          content: { symbol: "گروه ایرانیان", name: "گروه صنعتی ایرانیان" },
        },
        {
          id: "17046694194148349",
          content: { symbol: "ارشک", name: "سرمایه گذاری ارشک" },
        },
        {
          id: "34973883374080119",
          content: { symbol: "اعتضاد غدیر", name: "سرمایه گذاری اعتضاد غدیر" },
        },
        {
          id: "71054358604957484",
          content: {
            symbol: "پردازش اطلاعات",
            name: "پردازش اطلاعات ایرانیان",
          },
        },
        {
          id: "22922721400616304",
          content: { symbol: "مفتاح", name: "سرمایه گذاری مفتاح" },
        },
        {
          id: "42287375568679949",
          content: { symbol: "لیدکو", name: "گسترش صنعت علوم زیستی" },
        },
        {
          id: "16843404477481740",
          content: { symbol: "وسمحال", name: "شرکت س.استان چهارمحال وبختیاری" },
        },
        {
          id: "56687603998541386",
          content: { symbol: "اعتصام", name: "سرمایه گذاری اعتصام" },
        },
        {
          id: "65888228612962039",
          content: { symbol: "سیراف", name: "سرمایه گذاری سیراف" },
        },
        {
          id: "69994636304448345",
          content: { symbol: "زعیم", name: "سرمایه گذاری زعیم" },
        },
        {
          id: "15690804957883824",
          content: { symbol: "توسعه مجاب", name: "توسعه و سرمایه گذاری مجاب" },
        },
        {
          id: "41011426560490365",
          content: {
            symbol: "وسقزوین",
            name: "سرمایه گذاری عدالت استان قزوین",
          },
        },
        {
          id: "19990581743778955",
          content: { symbol: "مدار", name: "س. مدیریت سرمایه مدار" },
        },
        {
          id: "55879759904088706",
          content: { symbol: "وسمنان", name: "شرکت س استان سمنان" },
        },
        {
          id: "3955332316338258",
          content: { symbol: "وطوبی", name: "سرمایه گذاری اقتصاد شهر طوبی" },
        },
        {
          id: "48457557221009333",
          content: { symbol: "وتوسکا", name: "سرمایه گذاری توسعه توکا" },
        },
        {
          id: "60289595205403229",
          content: { symbol: "بهیر", name: "بین المللی س. ت. تجارت هیرمند" },
        },
        {
          id: "20946530370469828",
          content: { symbol: "ولیز", name: "لیزینگایران" },
        },
        {
          id: "48241092863917835",
          content: { symbol: "ولپارس", name: "لیزینگ پارسیان" },
        },
        {
          id: "45174198424472334",
          content: { symbol: "ولساپا", name: "لیزینگ رایان سایپا" },
        },
        {
          id: "23086515493897579",
          content: { symbol: "ولغدر", name: "لیزینگخودروغدیر" },
        },
        {
          id: "3149396562827132",
          content: { symbol: "وایران", name: "لیزینگ ایرانیان" },
        },
        {
          id: "66830065858417081",
          content: { symbol: "ولانا", name: "شرکت لیزینگ آریا دانا" },
        },
        {
          id: "11403770140000603",
          content: { symbol: "ولملت", name: "واسپاری ملت" },
        },
        {
          id: "71744682148776880",
          content: { symbol: "ولصنم", name: "لیزینگصنعتومعدن" },
        },
        {
          id: "31078457170311964",
          content: { symbol: "ولشرق", name: "لیزینگ ایران و شرق" },
        },
        {
          id: "61469668095573716",
          content: { symbol: "ولکار", name: "لیزینگ کارآفرین" },
        },
        {
          id: "48287670503317419",
          content: { symbol: "ولبهمن", name: "شرکت بهمن لیزینگ" },
        },
        {
          id: "12901875871456398",
          content: { symbol: "ولراز", name: "لیزینگ رازی" },
        },
        {
          id: "34621618468546063",
          content: { symbol: "ولتجار", name: "واسپاری تجارت وسرمایه ایرانیان" },
        },
        {
          id: "31187008559534062",
          content: {
            symbol: "واسپاری آیندگان",
            name: "واسپاری صنعت نفت آیندگان",
          },
        },
        {
          id: "5968811612822747",
          content: { symbol: "ولنوین", name: "لیزینگ اقتصاد نوین" },
        },
        {
          id: "14231831499205396",
          content: { symbol: "سنیر", name: "سیمان سفیدنیریز" },
        },
        {
          id: "611986653700161",
          content: { symbol: "سبزوا", name: "سیمان لار سبزوار" },
        },
        {
          id: "10171945867136336",
          content: { symbol: "سیتا", name: "سرمایه گذاری سیمان تامین" },
        },
        {
          id: "15521712617204216",
          content: { symbol: "سفارس", name: "سیمانفارسوخوزستان" },
        },
        {
          id: "30829203706095076",
          content: { symbol: "ستران", name: "سیمان تهران" },
        },
        {
          id: "10568944722570445",
          content: { symbol: "سصفها", name: "سیماناصفهان" },
        },
        {
          id: "26997316501080743",
          content: { symbol: "سشرق", name: "سیمان شرق" },
        },
        {
          id: "35669480110084448",
          content: { symbol: "سپاها", name: "سیمانسپاهان" },
        },
        {
          id: "13227300125161435",
          content: { symbol: "سصوفی", name: "سیمان صوفیان" },
        },
        {
          id: "15949743338644220",
          content: { symbol: "ساروم", name: "سیمانارومیه" },
        },
        {
          id: "27218386411183410",
          content: { symbol: "سدور", name: "سیمان دورود" },
        },
        {
          id: "41227201752535311",
          content: { symbol: "سفار", name: "سیمانفارس" },
        },
        {
          id: "14617104402836487",
          content: { symbol: "سیلام", name: "سیمان ایلام" },
        },
        {
          id: "41974758296041288",
          content: { symbol: "سخوز", name: "سیمان خوزستان" },
        },
        {
          id: "24807173016704795",
          content: { symbol: "سباقر", name: "سیمان باقران" },
        },
        {
          id: "4470657233334072",
          content: { symbol: "سخاش", name: "سیمانخاش" },
        },
        {
          id: "32347247706508046",
          content: { symbol: "ساوه", name: "سیمان ساوه" },
        },
        {
          id: "33808206014018431",
          content: { symbol: "سمازن", name: "سیمانمازندران" },
        },
        {
          id: "34890845654517313",
          content: { symbol: "ساربیل", name: "سیمان آرتا اردبیل" },
        },
        {
          id: "32525655729432562",
          content: { symbol: "سبهان", name: "سیمان بهبهان" },
        },
        {
          id: "11964419322927535",
          content: { symbol: "سرود", name: "سیمانشاهرود" },
        },
        {
          id: "4563413583000719",
          content: { symbol: "ساراب", name: "سیمان داراب" },
        },
        {
          id: "52220424531578944",
          content: { symbol: "سغرب", name: "سیمانغرب" },
        },
        {
          id: "55959112038778737",
          content: { symbol: "سخواف", name: "سیمان مجد خواف" },
        },
        {
          id: "15930821245168534",
          content: { symbol: "سکارون", name: "سیمان کارون" },
        },
        {
          id: "67327029014085707",
          content: { symbol: "سخزر", name: "سیمان خزر" },
        },
        {
          id: "4528607775462304",
          content: { symbol: "سفانو", name: "سیمان فارس نو" },
        },
        {
          id: "15472396110662150",
          content: { symbol: "سکرما", name: "سیمان کرمان" },
        },
        {
          id: "66295665969375744",
          content: { symbol: "سبجنو", name: "سیمان بجنورد" },
        },
        {
          id: "41284516796232939",
          content: { symbol: "سهگمت", name: "سیمانهگمتان" },
        },
        {
          id: "65321970913593427",
          content: { symbol: "سکرد", name: "سیمان کردستان" },
        },
        {
          id: "58035444268544991",
          content: { symbol: "سمتاز", name: "سیمان ممتازان کرمان" },
        },
        {
          id: "60654872678917533",
          content: { symbol: "سقاین", name: "سیمان قاین" },
        },
        {
          id: "61664227282090067",
          content: { symbol: "سلار", name: "شرکت سیمان لارستان" },
        },
        {
          id: "27000326841257664",
          content: { symbol: "سدشت", name: "صنایع سیمان دشتستان" },
        },
        {
          id: "6757220448540984",
          content: { symbol: "سشمال", name: "سیمان شمال" },
        },
        {
          id: "20034264486679145",
          content: { symbol: "سجام", name: "مجتمع سیمان غرب آسیا" },
        },
        {
          id: "37281199178613855",
          content: { symbol: "سیدکو", name: "سرمایه گذاری توسعه صنایع سیمان" },
        },
        {
          id: "29747059672582491",
          content: { symbol: "سهرمز", name: "سیمانهرمزگان" },
        },
        {
          id: "44802346787824971",
          content: { symbol: "ساروج", name: "بین المللی ساروج بوشهر" },
        },
        {
          id: "70883594945615893",
          content: { symbol: "سآبیک", name: "سیمان آبیک" },
        },
        {
          id: "68035927975704426",
          content: { symbol: "سخرم", name: "شرکت سیمان خرم آباد" },
        },
        {
          id: "69456730984618304",
          content: { symbol: "سغدیر", name: "توسعه سرمایه و صنعت غدیر" },
        },
        {
          id: "29407499034824131",
          content: { symbol: "سفیروز", name: "تولیدی سیمان فیروزکوه" },
        },
        {
          id: "52927543777190153",
          content: { symbol: "تهران بتون", name: "تهران بتون" },
        },
        {
          id: "21096748051392414",
          content: { symbol: "سغدیر", name: "توسعه سرمایه و صنعت غدیر" },
        },
        {
          id: "35331248532537562",
          content: { symbol: "اردستان", name: "سیمان اردستان" },
        },
        {
          id: "50633804639547462",
          content: { symbol: "کمرجان", name: "بازرگانی و تولیدی مرجان کار" },
        },
        {
          id: "19471788163911687",
          content: { symbol: "کفپارس", name: "فرآوردههای نسوز پارس" },
        },
        {
          id: "57086055330734195",
          content: { symbol: "کرازی", name: "کارخانجاتتولیدیشیشهرازی" },
        },
        {
          id: "65671173927025645",
          content: { symbol: "کتوکا", name: "تولیدی و خدمات صنایع نسوز توکا" },
        },
        {
          id: "4614779520007780",
          content: { symbol: "کسرا", name: "سرامیکهایصنعتیاردکان" },
        },
        {
          id: "20560887114747719",
          content: { symbol: "کسرام", name: "پارس سرام" },
        },
        {
          id: "57639364758870873",
          content: { symbol: "کپشیر", name: "پشمشیشهایران" },
        },
        {
          id: "34721884030854211",
          content: { symbol: "سفاسی", name: "شرکت فارسیت اهواز" },
        },
        {
          id: "28325731560106431",
          content: { symbol: "کساپا", name: "سایپاشیشه" },
        },
        {
          id: "67206358287598044",
          content: { symbol: "کهمدا", name: "شیشه همدان" },
        },
        {
          id: "49353447565507376",
          content: { symbol: "کاذر", name: "فرآوردههاینسوزآذر" },
        },
        {
          id: "54263829393913132",
          content: { symbol: "کمینا", name: "شیشه سازی مینا" },
        },
        {
          id: "62346804681275278",
          content: { symbol: "کگاز", name: "شیشه و گاز" },
        },
        {
          id: "23837844039713715",
          content: { symbol: "کفرا", name: "فراورده های نسوزایران" },
        },
        {
          id: "16405556680571453",
          content: { symbol: "کخاک", name: "صنایعخاکچینیایران" },
        },
        {
          id: "49627523909849331",
          content: { symbol: "کفرآور", name: "فرآورده های سیمان شرق" },
        },
        {
          id: "64155926828410021",
          content: { symbol: "ساذری", name: "آذریت" },
        },
        {
          id: "63499217872110599",
          content: { symbol: "کقزوی", name: "شیشه قزوین" },
        },
        {
          id: "4686607974846832",
          content: { symbol: "سفارود", name: "کارخانه فارسیت درود" },
        },
        {
          id: "10654052153538617",
          content: { symbol: "کابگن", name: "تولیدی و صنعتی آبگینه" },
        },
        {
          id: "62558705479545830",
          content: { symbol: "سایرا", name: "ایرانیت" },
        },
        {
          id: "27668158733246204",
          content: { symbol: "سپرمی", name: "پرمیت" },
        },
        {
          id: "55979741213594029",
          content: { symbol: "کباده", name: "تولید خاک نسوزاستقلال آباده" },
        },
        {
          id: "45608932669358493",
          content: { symbol: "کایتا", name: "ایتالران" },
        },
        {
          id: "42049553761321495",
          content: { symbol: "کورز", name: "ورزیران" },
        },
        {
          id: "26025177574491991",
          content: { symbol: "شلیا", name: "شرکت مواد ویژه لیا" },
        },
        {
          id: "69719691092117836",
          content: { symbol: "توس", name: "سپیده جام توس" },
        },
        {
          id: "11452654295102268",
          content: { symbol: "مقره سازی", name: "مقره سازی ایران" },
        },
        {
          id: "56881008607657214",
          content: { symbol: "توکا بتن", name: "توکا بتن" },
        },
        {
          id: "70391097626818082",
          content: { symbol: "گشان", name: "توسعه بین المللی پدیده شاندیز" },
        },
        {
          id: "10114441830266109",
          content: { symbol: "گدنا", name: "تهیه توزیع غذای دنا آفرین فدک" },
        },
        {
          id: "46741025610365786",
          content: { symbol: "سمگا", name: "گروه سرمایه گذاری میراث فرهنگی" },
        },
        {
          id: "66599109405217136",
          content: { symbol: "گکوثر", name: "هتل پارسیان کوثر اصفهان" },
        },
        {
          id: "44665761767777759",
          content: { symbol: "گکیش", name: "توریستی ورفاهی آبادگران کیش" },
        },
        {
          id: "59848307608894801",
          content: { symbol: "گپارس", name: "امور رفاهی کارگزاران پارس" },
        },
        {
          id: "59470107928175959",
          content: { symbol: "گنگین", name: "اقتصادی نگین گردشگری ایرانیان" },
        },
        {
          id: "21551806741582786",
          content: { symbol: "هما", name: "هتلهای هما" },
        },
        {
          id: "1808967844081601",
          content: { symbol: "خدمات نوساز", name: "ساختمان و خدمات نوساز" },
        },
        {
          id: "49703334175145583",
          content: { symbol: "توسعه گردشگری", name: "توسعه گردشگری ایران" },
        },
        {
          id: "52082232242833024",
          content: { symbol: "هوایی سامان", name: "خدمات هوایی سامان" },
        },
        {
          id: "31212063757944288",
          content: {
            symbol: "مخازن پتروشیمی",
            name: "پایانه ها و مخازن پتروشیمی",
          },
        },
        {
          id: "61038685850454369",
          content: { symbol: "صیدک404", name: "صکوک مرابحه صیدک404-3ماهه18%" },
        },
        {
          id: "52894922031595796",
          content: { symbol: "بدکو", name: "شرکت فروشگاه زنجیره ای دیاکو" },
        },
        {
          id: "66362433671981035",
          content: {
            symbol: "صیدک1404",
            name: "صکوک اجاره سایپا یدک-3ماهه18%",
          },
        },
        {
          id: "32678431934327184",
          content: { symbol: "کلوند", name: "کاشی الوند" },
        },
        {
          id: "9698674686691945",
          content: { symbol: "کپارس", name: "کاشی پارس" },
        },
        {
          id: "24085906177899789",
          content: { symbol: "کترام", name: "تولیدی کاشی تکسرام" },
        },
        {
          id: "25001509088465005",
          content: { symbol: "کساوه", name: "صنایع کاشی و سرامیک سینا" },
        },
        {
          id: "32257753560585502",
          content: { symbol: "کحافظ", name: "کاشی وسرامیک حافظ" },
        },
        {
          id: "25631699615003698",
          content: { symbol: "کهرام", name: "تولیدی گرانیت بهسرام" },
        },
        {
          id: "29122854902865456",
          content: { symbol: "کسعدی", name: "کاشی سعدی" },
        },
        {
          id: "42696242981550091",
          content: { symbol: "کصدف", name: "کاشی صدف سرام استقلال آباده" },
        },
        {
          id: "53716725737664022",
          content: { symbol: "کچینی", name: "چینی ایران" },
        },
        {
          id: "61085772216153547",
          content: { symbol: "کاصفا", name: "صنایع کاشی اصفهان" },
        },
        {
          id: "50076347623649084",
          content: { symbol: "کآرا", name: "صنایع سرام آرا" },
        },
        {
          id: "71811061818706620",
          content: { symbol: "گلسار", name: "گلسار فارس" },
        },
        {
          id: "4687803927401790",
          content: { symbol: "کنیلو", name: "کاشی نیلو" },
        },
        {
          id: "5325443374224010",
          content: { symbol: "کنگار", name: "کاشی و سرامیک نگار گستر جلگه" },
        },
        {
          id: "12549470190400301",
          content: { symbol: "سرامیک البرز", name: "سرامیک البرز" },
        },
        {
          id: "50965286666634546",
          content: { symbol: "کاشی کاشمر", name: "تولید کاشی زهره کاشمر" },
        },
        {
          id: "41482283888686471",
          content: { symbol: "هواپیمایی کارون", name: "هواپیمایی کارون" },
        },
        {
          id: "7832557514882006",
          content: { symbol: "ایرا ن ایر", name: "هواپیمایی ج.ا.ا-هما" },
        },
        {
          id: "49502666250908008",
          content: { symbol: "افق", name: "فروشگاههای زنجیره ای افق کوروش" },
        },
        {
          id: "34540569618314880",
          content: { symbol: "قاسم", name: "قاسم ایران" },
        },
        {
          id: "33410001534682920",
          content: { symbol: "رفاه", name: "فروشگاه های زنجیره ای رفاه" },
        },
        {
          id: "39884565732277052",
          content: { symbol: "کیمیاتک", name: "آریان کیمیا تک" },
        },
        {
          id: "19040514831923530",
          content: { symbol: "نوری", name: "پتروشیمی نوری" },
        },
        {
          id: "69143674941561637",
          content: { symbol: "پترول", name: "گروه پتروشیمی س.ایرانیان" },
        },
        {
          id: "5987841496184505",
          content: { symbol: "شگویا", name: "پتروشیمی تندگویان" },
        },
        {
          id: "20562694899904339",
          content: { symbol: "شپدیس", name: "پتروشیمی پردیس" },
        },
        {
          id: "22560050433388046",
          content: { symbol: "تاپیکو", name: "س. نفت و گاز و پتروشیمی تامین" },
        },
        {
          id: "25244329144808274",
          content: { symbol: "فارس", name: "صنایع پتروشیمی خلیج فارس" },
        },
        {
          id: "7711282667602555",
          content: { symbol: "شاراک", name: "پتروشیمی شازند" },
        },
        {
          id: "55127657985997520",
          content: { symbol: "آریا", name: "پلیمر آریا ساسول" },
        },
        {
          id: "23441366113375722",
          content: { symbol: "پارسان", name: "گسترش نفت و گاز پارسیان" },
        },
        {
          id: "50247622569476338",
          content: { symbol: "بوعلی", name: "پتروشیمی بوعلی سینا" },
        },
        {
          id: "43552974795606067",
          content: { symbol: "خراسان", name: "پتروشیمی خراسان" },
        },
        {
          id: "13235547361447092",
          content: { symbol: "زاگرس", name: "پتروشیمی زاگرس" },
        },
        {
          id: "65122215875355555",
          content: { symbol: "شفن", name: "پتروشیمی فناوران" },
        },
        {
          id: "21772258644715569",
          content: { symbol: "شغدیر", name: "پتروشیمی غدیر" },
        },
        {
          id: "38437201078089290",
          content: { symbol: "کرماشا", name: "صنایع پتروشیمی کرمانشاه" },
        },
        {
          id: "28845264556937486",
          content: { symbol: "شپلی", name: "پلی اکریل ایران" },
        },
        {
          id: "204092872752957",
          content: { symbol: "شصدف", name: "صنعتی دوده فام" },
        },
        {
          id: "56429431740318486",
          content: { symbol: "شلرد", name: "کود شیمیایی اوره لردگان" },
        },
        {
          id: "30974710508383145",
          content: { symbol: "شسینا", name: "صنایعشیمیاییسینا" },
        },
        {
          id: "35796086458096255",
          content: { symbol: "شیران", name: "س. صنایعشیمیاییایران" },
        },
        {
          id: "70934270174405743",
          content: { symbol: "شخارک", name: "پتروشیمی خارک" },
        },
        {
          id: "1822787329898392",
          content: { symbol: "کلر", name: "کلر پارس" },
        },
        {
          id: "6110133418282108",
          content: { symbol: "پارس", name: "پتروشیمی پارس" },
        },
        {
          id: "38555056423456635",
          content: { symbol: "شتوکا", name: "توکا رنگ فولاد سپاهان" },
        },
        {
          id: "38568786927478796",
          content: { symbol: "شیراز", name: "پتروشیمیشیراز" },
        },
        {
          id: "71957984642204570",
          content: { symbol: "شپترو", name: "پتروشیمی آبادان" },
        },
        {
          id: "3407806799514469",
          content: { symbol: "شاروم", name: "پتروشیمی ارومیه" },
        },
        {
          id: "69446612239102459",
          content: { symbol: "شکام", name: "صنایع شیمیایی کیمیاگران امروز" },
        },
        {
          id: "39610074039667804",
          content: { symbol: "قرن", name: "پدیده شیمی قرن" },
        },
        {
          id: "43781018754867729",
          content: { symbol: "شفارس", name: "صنایع شیمیایی فارس" },
        },
        {
          id: "27096851668435724",
          content: { symbol: "جم پیلن", name: "پلی پروپیلن جم - جم پیلن" },
        },
        {
          id: "27308217070238237",
          content: { symbol: "شکربن", name: "کربن ایران" },
        },
        {
          id: "39116664428676213",
          content: { symbol: "شلعاب", name: "لعابیران" },
        },
        {
          id: "32357363984168442",
          content: { symbol: "جم", name: "پتروشیمی جم" },
        },
        {
          id: "68517032834363488",
          content: { symbol: "شبصیر", name: "تولیدات پتروشیمی قاید بصیر" },
        },
        {
          id: "62177651435283872",
          content: { symbol: "شکلر", name: "نیروکلر" },
        },
        {
          id: "40611478183231802",
          content: { symbol: "شدوص", name: "دوده صنعتی پارس" },
        },
        {
          id: "62786156501584862",
          content: { symbol: "پاکشو", name: "گروه صنعتی پاکشو" },
        },
        {
          id: "12303918642491681",
          content: { symbol: "زنجان", name: "صنایع کشاورزی وکود زنجان" },
        },
        {
          id: "16673205196919832",
          content: { symbol: "شفارا", name: "پتروشیمی فارابی" },
        },
        {
          id: "40025799067544201",
          content: { symbol: "شرنگی", name: "شیمیایی رنگین" },
        },
        {
          id: "59486059679335017",
          content: { symbol: "وپترو", name: "سرمایهگذاری صنایع پتروشیمی" },
        },
        {
          id: "64298008532791199",
          content: { symbol: "ساینا", name: "صنایع بهداشتی ساینا" },
        },
        {
          id: "61102694810476197",
          content: { symbol: "شپارس", name: "بین المللی محصولات پارس" },
        },
        {
          id: "3173544097113770",
          content: { symbol: "شستان", name: "پتروشیمی گلستان" },
        },
        {
          id: "56574323121551263",
          content: { symbol: "شکبیر", name: "پتروشیمی امیرکبیر" },
        },
        {
          id: "6116572045021585",
          content: { symbol: "شجم", name: "صنایع پتروشیمی تخت جمشید" },
        },
        {
          id: "53449700212786324",
          content: { symbol: "مارون", name: "پتروشیمی مارون" },
        },
        {
          id: "11622051128546106",
          content: { symbol: "شپاکسا", name: "پاکسان" },
        },
        {
          id: "18346219759153870",
          content: { symbol: "شصفها", name: "پتروشیمی اصفهان" },
        },
        {
          id: "318005355896147",
          content: { symbol: "فسا", name: "پتروشیمی فسا" },
        },
        {
          id: "3493306453706327",
          content: { symbol: "شوینده", name: "مدیریت صنعت شوینده ت.ص.بهشهر" },
        },
        {
          id: "49674915481184052",
          content: { symbol: "جهرم", name: "پتروشیمی جهرم" },
        },
        { id: "44153164692325703", content: { symbol: "شگل", name: "گلتاش" } },
        {
          id: "16959429956899455",
          content: { symbol: "شاملا", name: "معدنی املاح ایران" },
        },
        {
          id: "27299841173245405",
          content: { symbol: "داراب", name: "پتروشیمی داراب" },
        },
        { id: "58602432837130018", content: { symbol: "شکف", name: "کف" } },
        {
          id: "66210395067138534",
          content: { symbol: "شتولی", name: "تولیپرس" },
        },
        {
          id: "59800986739603675",
          content: { symbol: "شسم", name: "تولید سموم علف کش" },
        },
        {
          id: "21607242972640064",
          content: { symbol: "شغدیر", name: "پتروشیمی غدیر" },
        },
        {
          id: "15374483986949695",
          content: { symbol: "دهدشت", name: "صنایع پتروشیمی دهدشت" },
        },
        {
          id: "7505990056227818",
          content: { symbol: "ممسنی", name: "پتروشیمی ممسنی" },
        },
        {
          id: "16777570760181431",
          content: { symbol: "کازرو", name: "پتروشیمی کازرون" },
        },
        {
          id: "28251956446987982",
          content: { symbol: "شمواد", name: "تولید مواداولیه الیاف مصنوعی" },
        },
        {
          id: "49244604018250364",
          content: { symbol: "شجم", name: "صنایع پتروشیمی تخت جمشید" },
        },
        {
          id: "1050751214677134",
          content: { symbol: "شگامرن", name: "مجتمع پترو صنعت گامرون" },
        },
        {
          id: "15582754946564355",
          content: { symbol: "زنجان", name: "پتروشیمی زنجان" },
        },
        {
          id: "47885768161625196",
          content: { symbol: "صفارس307", name: "صکوک اجاره فارس307- 3ماهه18%" },
        },
        {
          id: "61653278859973520",
          content: { symbol: "پارسا306", name: "صکوک اجاره پارسیان-6ماهه16%" },
        },
        {
          id: "41309167317349268",
          content: {
            symbol: "صفارس412",
            name: "صکوک اجاره خلیج فارس- 3ماهه16%",
          },
        },
        {
          id: "8447376524514895",
          content: { symbol: "صفارس147", name: "صکوک اجاره فارس147- 3ماهه18%" },
        },
        {
          id: "44345102023100322",
          content: { symbol: "شپمچا", name: "پارس پامچال" },
        },
        {
          id: "57714949492552862",
          content: { symbol: "پتروشیمی مرجان", name: "پتروشیمی مرجان" },
        },
        {
          id: "59365872074856298",
          content: { symbol: "صفارس037", name: "صکوک اجاره فارس037- 3ماهه18%" },
        },
        {
          id: "35478981890584027",
          content: { symbol: "پتروباختر", name: "پتروشیمی باختر" },
        },
        {
          id: "19760719619611064",
          content: {
            symbol: "پتروشیمی کوروش",
            name: "توسعه صنایع پتروشیمی کوروش",
          },
        },
        {
          id: "31262218550324764",
          content: { symbol: "پترو کوهدشت", name: "توسعه پتروشیمی کوهدشت" },
        },
        {
          id: "57857218314224912",
          content: { symbol: "پذیره-ستون", name: "پتروشیمی بیستون" },
        },
        {
          id: "53986010692093246",
          content: { symbol: "پترو دهدشت", name: "پتروشیمی دهدشت" },
        },
        {
          id: "21164477711564061",
          content: { symbol: "پتروشیمی تبریز", name: "پتروشیمی تبریز" },
        },
        {
          id: "17299508146321256",
          content: { symbol: "پتروکاویان", name: "پتروشیمی کاویان" },
        },
        {
          id: "58356374066358100",
          content: { symbol: "اروند", name: "پتروشیمی اروند" },
        },
        {
          id: "70989890248767040",
          content: { symbol: "پتروممسنی", name: "پتروشیمی ممسنی" },
        },
        {
          id: "23100379978035731",
          content: { symbol: "بوعلی", name: "شرکت پتروشیمی بوعلی سینا" },
        },
        {
          id: "44894482408367404",
          content: { symbol: "پلینار", name: "پلی نار" },
        },
        {
          id: "35113075091643530",
          content: { symbol: "پترو گچساران", name: "پتروشیمی گچساران" },
        },
        {
          id: "23781657998194558",
          content: { symbol: "قطران", name: "پالایش قطران ذغالسنگ" },
        },
        {
          id: "60033291068807352",
          content: { symbol: "کاتالیست", name: "گسترش کاتالیست ایرانیان" },
        },
        {
          id: "28683348852986692",
          content: { symbol: "هگمتانه", name: "صنایع پتروشیمی هگمتانه" },
        },
        {
          id: "70131166122617924",
          content: { symbol: "پترومروارید", name: "پتروشیمی مروارید" },
        },
        {
          id: "4287217773498527",
          content: { symbol: "تولیددارو", name: "تولیددارو" },
        },
        {
          id: "15391057003393823",
          content: {
            symbol: "صگستر504",
            name: "صکوک اجاره صگستر504- 6ماهه18%",
          },
        },
        {
          id: "16553062355259729",
          content: { symbol: "بمیلا", name: "توسعه بازرگانی آهن وفولادمیلاد" },
        },
        {
          id: "33474290111427408",
          content: { symbol: "برنا", name: "بازرگانی برنا" },
        },
        {
          id: "67526366820181168",
          content: { symbol: "بازرگانی معادن", name: "بازرگانی معادن ایران" },
        },
        {
          id: "2318736941376687",
          content: { symbol: "خصدرا", name: "صنعتی دریایی ایران" },
        },
        {
          id: "8646067353086740",
          content: { symbol: "بالاس", name: "مهندسی ساختمان تاسیسات راه آهن" },
        },
        {
          id: "11560002870991149",
          content: { symbol: "وپسا", name: "س. پتروشیمی ساختمان خلیج فارس" },
        },
        {
          id: "3236505307755709",
          content: { symbol: "نوسازی صنایع", name: "نوسازی صنایع ایران" },
        },
        {
          id: "62116999248163434",
          content: { symbol: "تکاپو", name: "طراحی و احداث صنایع تکاپو" },
        },
        {
          id: "33629260529503413",
          content: { symbol: "بجهرم", name: "توسعه مولد نیروگاهی جهرم" },
        },
        {
          id: "66142616039907394",
          content: { symbol: "دماوند", name: "تولید نیروی برق دماوند" },
        },
        {
          id: "37661500521100963",
          content: { symbol: "آبادا", name: "تولید نیروی برق آبادان" },
        },
        {
          id: "7628308021169118",
          content: { symbol: "بکهنوج", name: "تولید برق ماهتاب کهنوج" },
        },
        {
          id: "64619251116188373",
          content: { symbol: "بپیوند", name: "برق و انرژی پیوندگستر پارس" },
        },
        {
          id: "62603302940123327",
          content: { symbol: "ونیرو", name: "سرمایهگذارینیرو" },
        },
        {
          id: "25215182208950217",
          content: { symbol: "وهور", name: "مدیریت انرژی امید تابان هور" },
        },
        {
          id: "39807886630843041",
          content: { symbol: "بمپنا", name: "تولید برق عسلویه مپنا" },
        },
        {
          id: "29882244560576007",
          content: { symbol: "بزاگرس", name: "نیروگاه زاگرس کوثر" },
        },
        {
          id: "27922860956133067",
          content: { symbol: "مبین", name: "مبین انرژی خلیج فارس" },
        },
        {
          id: "71068313834275501",
          content: { symbol: "بگیلان", name: "توسعه مسیر برق گیلان" },
        },
        {
          id: "46178280540110577",
          content: { symbol: "بفجر", name: "فجر انرژی خلیج فارس" },
        },
        {
          id: "30801484454172783",
          content: { symbol: "بصبا", name: "تولید نیروی جنوب غرب صبا" },
        },
        {
          id: "64572690242350087",
          content: { symbol: "پترو دماوند", name: "پتروشیمی دماوند" },
        },
        {
          id: "5683112369371738",
          content: { symbol: "برق مپنا", name: "تولید برق پرند مپنا" },
        },
        {
          id: "6802043070704889",
          content: { symbol: "انرژی سپهر", name: "توسعه برق و انرژی سپهر" },
        },
        {
          id: "60797910768542050",
          content: { symbol: "نیروگاه دز", name: "بهره برداری نیروگاه دز" },
        },
        {
          id: "11006334882585136",
          content: {
            symbol: "نیروگاه عباسپور",
            name: "بهره برداری نیروگاه عباسپور",
          },
        },
        {
          id: "35615878946229935",
          content: { symbol: "برق آبادان", name: "تولید نیروی برق آبادان" },
        },
        {
          id: "17833511227457172",
          content: { symbol: "پتروشیمی دماوند", name: "پتروشیمی دماوند" },
        },
        {
          id: "42252159652497300",
          content: { symbol: "مشاور نیرو", name: "مهندسین مشاور نیرو" },
        },
        {
          id: "49800628721358056",
          content: { symbol: "برق منجیل", name: "تولید نیروی برق سبز منجیل" },
        },
        {
          id: "39926279125948465",
          content: { symbol: "سهند", name: "تولید نیروی برق سهند" },
        },
        {
          id: "38565134635041719",
          content: { symbol: "نیروگاه فارس", name: "مولد نیروگاهی تجارت فارس" },
        },
        {
          id: "19074684560939209",
          content: { symbol: "ومپنا", name: "مدیریت نیروگاهی ایرانیان مپنا" },
        },
        {
          id: "70235146834251308",
          content: { symbol: "خلیج فارس", name: "تولید نیروی برق خلیج فارس" },
        },
        {
          id: "22299894048845903",
          content: { symbol: "غگل", name: "گلوکوزان" },
        },
        {
          id: "22956708386610464",
          content: { symbol: "خودکفا", name: "اقتصادی و خودکفایی آزادگان" },
        },
        {
          id: "2254054929817435",
          content: { symbol: "غدام", name: "خوراک دام پارس" },
        },
        {
          id: "4369934250728330",
          content: { symbol: "غزر", name: "صنعتی زر ماکارون" },
        },
        {
          id: "48619517949257749",
          content: { symbol: "غنوش", name: "نوشمازندران" },
        },
        {
          id: "71758511001096824",
          content: { symbol: "غصینو", name: "صنعتی مینو" },
        },
        {
          id: "18401147983387689",
          content: { symbol: "غپینو", name: "پارس مینو" },
        },
        {
          id: "61506294208022391",
          content: { symbol: "غشصفا", name: "شیرپاستوریزهپگاهاصفهان" },
        },
        {
          id: "62404730109947970",
          content: { symbol: "غکورش", name: "صنعت غذایی کورش" },
        },
        {
          id: "57300230097485720",
          content: { symbol: "غگیلا", name: "شیرپاستوریزه پگاه گیلان" },
        },
        {
          id: "24303422207378456",
          content: { symbol: "غالبر", name: "لبنیاتکالبر" },
        },
        {
          id: "37842793167868642",
          content: { symbol: "غویتا", name: "ویتانا" },
        },
        {
          id: "3492952121304423",
          content: { symbol: "غدیس", name: "پاکدیس" },
        },
        {
          id: "28672095850798501",
          content: { symbol: "غسالم", name: "سالمین" },
        },
        {
          id: "33611155027418901",
          content: { symbol: "غشهداب", name: "کشت و صنعت شهداب ناب خراسان" },
        },
        {
          id: "16410724132711490",
          content: { symbol: "غفارس", name: "شیر پاستوریزه پگاه فارس" },
        },
        {
          id: "17059960254855208",
          content: { symbol: "غبهنوش", name: "بهنوش ایران" },
        },
        {
          id: "31024260997481994",
          content: { symbol: "غگرجی", name: "بیسکویت گرجی" },
        },
        {
          id: "6131290133202745",
          content: { symbol: "غمهرا", name: "تولیدیمهرام" },
        },
        {
          id: "35158826900216508",
          content: { symbol: "غاذر", name: "کشتوصنعتپیاذر" },
        },
        {
          id: "53204330224889981",
          content: { symbol: "تبرک", name: "گروه کارخانجات صنعتی تبرک" },
        },
        {
          id: "14312030900097668",
          content: { symbol: "غگلپا", name: "شیر پاستوریزه پگاه گلپایگان" },
        },
        {
          id: "34032872653290886",
          content: { symbol: "غپاک", name: "لبنیات پاک" },
        },
        {
          id: "52975109254504632",
          content: { symbol: "غمارگ", name: "مارگارین" },
        },
        {
          id: "64843936383937546",
          content: { symbol: "غگلستا", name: "شیر پاستوریزه پگاه گلستان" },
        },
        {
          id: "13937270451301973",
          content: { symbol: "وبشهر", name: "توسعه صنایع بهشهر(هلدینگ" },
        },
        {
          id: "12746730665870442",
          content: { symbol: "بهپاک", name: "صنعتی بهپاک" },
        },
        {
          id: "42387718866026650",
          content: { symbol: "غبشهر", name: "صنعتی بهشهر" },
        },
        {
          id: "44850033148208596",
          content: { symbol: "غچین", name: "کشت و صنعت چین چین" },
        },
        {
          id: "59921975187856916",
          content: { symbol: "غشاذر", name: "پگاهآذربایجانغربی" },
        },
        {
          id: "20487994977117557",
          content: { symbol: "غشهد", name: "شهد ایران" },
        },
        {
          id: "45518744711972166",
          content: { symbol: "غپآذر", name: "شیر پگاه آذربایجان شرقی" },
        },
        {
          id: "71666521540545716",
          content: { symbol: "غبهار", name: "کشت وصنعت بهاران گلبهار خراسان" },
        },
        {
          id: "31791737198597563",
          content: { symbol: "غشان", name: "شیرپاستوریزهپگاهخراسان" },
        },
        {
          id: "51294484197536070",
          content: { symbol: "غشوکو", name: "شوکو پارس" },
        },
        {
          id: "5516102131364383",
          content: { symbol: "غمینو", name: "شرکت صنایع غذایی مینو شرق" },
        },
        {
          id: "33736447482634583",
          content: { symbol: "غپونه", name: "نوش پونه مشهد" },
        },
        { id: "57551382352708199", content: { symbol: "غگز", name: "گز سکه" } },
        {
          id: "21426277483799140",
          content: { symbol: "غنیلی", name: "مجتمع تولیدی نیلی صنعت کرمان" },
        },
        {
          id: "2434703913394836",
          content: { symbol: "غدشت", name: "دشت مرغاب" },
        },
        {
          id: "50652985928800943",
          content: { symbol: "غیوان", name: "کیوان" },
        },
        {
          id: "42470251469508137",
          content: { symbol: "غناب", name: "روغن نباتی ناب" },
        },
        {
          id: "25018856872742529",
          content: { symbol: "غنیلیح", name: "مجتمع تولیدی نیلی صنعت کرمان" },
        },
        {
          id: "39407748456166789",
          content: { symbol: "چین چین", name: "کشت و صنعت بین الملل چین چین" },
        },
        {
          id: "37115219225815800",
          content: { symbol: "آرد تجارت", name: "آرد تجارت" },
        },
        {
          id: "39083655150097905",
          content: { symbol: "صنایع گلستان", name: "صنایع تبدیلی گلستان" },
        },
        {
          id: "39299665139769418",
          content: { symbol: "صنایع شیر", name: "صنایع شیر سرو بی خزان" },
        },
        {
          id: "8234855558996646",
          content: { symbol: "غدانه", name: "کارخانجات دانه روغنی خراسان" },
        },
        {
          id: "34557241988629814",
          content: { symbol: "برکت", name: "گروه دارویی برکت" },
        },
        {
          id: "69090868458637360",
          content: { symbol: "دیران", name: "ایراندارو" },
        },
        {
          id: "28431095903407567",
          content: { symbol: "کاسپین", name: "داروسازی کاسپین تامین" },
        },
        {
          id: "6478064539164167",
          content: { symbol: "ریشمک", name: "تولید و صادرات ریشمک" },
        },
        {
          id: "47348197320716810",
          content: { symbol: "دسانکو", name: "داروسازی سبحان انکولوژی" },
        },
        {
          id: "18093681647131179",
          content: { symbol: "دتولید", name: "داروسازی تولید دارو" },
        },
        {
          id: "40262275031537922",
          content: { symbol: "دروز", name: "داروسازی روزدارو" },
        },
        {
          id: "57944184894703821",
          content: { symbol: "والبر", name: "سرمایه گذاری البرز(هلدینگ" },
        },
        {
          id: "71510396252618330",
          content: { symbol: "دبالک", name: "مواد اولیه دارویی البرز بالک" },
        },
        {
          id: "66450490505950110",
          content: { symbol: "ددام", name: "داروسازی زاگرس فارمد پارس" },
        },
        {
          id: "13243992182070788",
          content: { symbol: "درهآور", name: "دارویی ره آورد تامین" },
        },
        {
          id: "29247915161590165",
          content: { symbol: "دلقما", name: "دارویی لقمان" },
        },
        {
          id: "8915450910866216",
          content: { symbol: "دزهراوی", name: "داروسازیزهراوی" },
        },
        {
          id: "66726992874614788",
          content: { symbol: "دتوزیع", name: "توزیع دارو پخش" },
        },
        {
          id: "4384288570322406",
          content: { symbol: "دلر", name: "داروسازی اکسیر" },
        },
        {
          id: "12638840758449459",
          content: { symbol: "پخش", name: "پخش البرز" },
        },
        {
          id: "33603212156438463",
          content: { symbol: "دشیمی", name: "شیمی دارویی داروپخش" },
        },
        {
          id: "33406621820337161",
          content: { symbol: "دجابر", name: "داروسازی جابرابنحیان" },
        },
        {
          id: "52382684379473036",
          content: { symbol: "دکپسول", name: "تولید ژلاتین کپسول ایران" },
        },
        {
          id: "23353689102956991",
          content: { symbol: "دکوثر", name: "داروسازی کوثر" },
        },
        {
          id: "7183333492448248",
          content: { symbol: "وپخش", name: "داروپخش (هلدینگ" },
        },
        {
          id: "5305844922895340",
          content: { symbol: "داوه", name: "داروسازی آوه سینا" },
        },
        {
          id: "60451823714332895",
          content: { symbol: "دالبر", name: "البرزدارو" },
        },
        {
          id: "49054891736433700",
          content: { symbol: "دعبید", name: "لابراتوارداروسازی دکترعبیدی" },
        },
        {
          id: "12387472624849835",
          content: { symbol: "داسوه", name: "داروسازی اسوه" },
        },
        {
          id: "43622578471330344",
          content: { symbol: "دسبحا", name: "گروه دارویی سبحان" },
        },
        {
          id: "22255783119783047",
          content: { symbol: "درازک", name: "دارویی رازک" },
        },
        {
          id: "34641719089573667",
          content: { symbol: "دتماد", name: "تولیدمواداولیهداروپخش" },
        },
        {
          id: "62977319271289925",
          content: { symbol: "کی بی سی", name: "شرکت کی بی سی" },
        },
        {
          id: "5866848234665627",
          content: { symbol: "دسبحان", name: "سبحان دارو" },
        },
        {
          id: "50100062518826135",
          content: { symbol: "دامین", name: "داروسازی امین" },
        },
        {
          id: "56550776668133562",
          content: { symbol: "دفارا", name: "داروسازی فارابی" },
        },
        {
          id: "29758477602878557",
          content: { symbol: "تیپیکو", name: "سرمایه گذاری دارویی تامین" },
        },
        {
          id: "36899214178084525",
          content: { symbol: "شفا", name: "سرمایه گذاری شفادارو" },
        },
        {
          id: "5317427172344706",
          content: { symbol: "هجرت", name: "پخش هجرت" },
        },
        {
          id: "61332057061846617",
          content: { symbol: "دابور", name: "داروسازی ابوریحان" },
        },
        {
          id: "43374709930371268",
          content: { symbol: "دحاوی", name: "الحاوی" },
        },
        {
          id: "67988012428906654",
          content: { symbol: "دارو", name: "کارخانجاتداروپخش" },
        },
        {
          id: "20024911381434086",
          content: { symbol: "دکیمی", name: "کیمیدارو" },
        },
        {
          id: "11432067920374603",
          content: { symbol: "دسینا", name: "داروسازی سینا" },
        },
        {
          id: "70474983732269112",
          content: { symbol: "دپارس", name: "پارس دارو" },
        },
        {
          id: "63084741752814852",
          content: { symbol: "دشیری", name: "شیرین دارو" },
        },
        {
          id: "18303237082155264",
          content: { symbol: "دفرا", name: "فرآوردههای تزریقی ایران" },
        },
        {
          id: "64289770858657141",
          content: { symbol: "دقاضی", name: "داروسازی شهید قاضی" },
        },
        {
          id: "14985138705106402",
          content: { symbol: "دتهران", name: "داروسازی تهران دارو" },
        },
        {
          id: "31049085025064185",
          content: { symbol: "شتهران", name: "داروسازی تهران شیمی" },
        },
        {
          id: "27148595522452496",
          content: {
            symbol: "صینا205",
            name: "صکوک منفعت سینا دارو-3ماهه 19%",
          },
        },
        {
          id: "57654952942460685",
          content: { symbol: "صعبید312", name: "صکوک اجاره دعبید-3ماهه18%" },
        },
        {
          id: "69540585676934415",
          content: { symbol: "ددانا", name: "داروسازی دانا" },
        },
        {
          id: "16567465928886309",
          content: { symbol: "درازی", name: "پخش رازی" },
        },
        {
          id: "47973532381204870",
          content: { symbol: "بیوتیک", name: "آنتی بیوتیک سازی ایران" },
        },
        {
          id: "44967158778304588",
          content: { symbol: "قثابت", name: "قند ثابت خراسان" },
        },
        {
          id: "63380098535169030",
          content: { symbol: "قنیشا", name: "قند نیشابور" },
        },
        {
          id: "40411537531154482",
          content: { symbol: "قصفها", name: "قنداصفهان" },
        },
        {
          id: "71523986304961239",
          content: { symbol: "قشیر", name: "قند شیروان قوچان و بجنورد" },
        },
        {
          id: "4267564158935326",
          content: { symbol: "قیستو", name: "شرکت قند بیستون" },
        },
        {
          id: "35964395659427029",
          content: { symbol: "قشکر", name: "شکرشاهرود" },
        },
        {
          id: "56820995669577571",
          content: { symbol: "قلرست", name: "قند لرستان" },
        },
        {
          id: "36671655475498480",
          content: { symbol: "قشرین", name: "صنعتی و کشاورزی شیرین خراسان" },
        },
        {
          id: "15259343650667588",
          content: { symbol: "قزوین", name: "کارخانجات قند قزوین" },
        },
        { id: "37631109616997982", content: { symbol: "قشهد", name: "شهد" } },
        {
          id: "3050342257199174",
          content: { symbol: "قنقش", name: "قند نقش جهان" },
        },
        {
          id: "23600798892801694",
          content: { symbol: "قچار", name: "فراوردههای غذایی وقند چهارمحال" },
        },
        {
          id: "43342306308122676",
          content: { symbol: "قمرو", name: "قند مرودشت" },
        },
        {
          id: "28230238564334914",
          content: { symbol: "قجام", name: "فرآوردههای غذایی وقندتربتجام" },
        },
        {
          id: "35515916857426389",
          content: { symbol: "قتربت", name: "قند تربت حیدریه" },
        },
        {
          id: "67030488744129337",
          content: { symbol: "قپیرا", name: "فرآوردههایغداییوقندپیرانشهر" },
        },
        {
          id: "10831074117626896",
          content: { symbol: "قاروم", name: "قند ارومیه" },
        },
        {
          id: "14398278072324784",
          content: { symbol: "قهکمت", name: "قندهکمتان" },
        },
        {
          id: "17605403389426895",
          content: {
            symbol: "نیشکر هفت تپه",
            name: "کشت و صنعت نیشکر هفت تپه",
          },
        },
        {
          id: "54027919601285197",
          content: {
            symbol: "قند یاسوج",
            name: "فرآورده های غذایی قندی یاسوج",
          },
        },
        {
          id: "66668962036864944",
          content: { symbol: "توسعه نیشکر", name: "توسعه نیشکر و صنایع جانبی" },
        },
        {
          id: "2400322364771558",
          content: { symbol: "شستا", name: "سرمایه گذاری تامین اجتماعی" },
        },
        {
          id: "26014913469567886",
          content: { symbol: "وغدیر", name: "سرمایهگذاریغدیر(هلدینگ" },
        },
        {
          id: "48010225447410247",
          content: { symbol: "وبانک", name: "سرمایه گذاری گروه توسعه ملی" },
        },
        {
          id: "25336820825905643",
          content: { symbol: "ونیکی", name: "سرمایهگذاری ملیایران" },
        },
        {
          id: "37204371816016200",
          content: { symbol: "وصندوق", name: "سرمایه گذاری صندوق بازنشستگی" },
        },
        {
          id: "52232388263291380",
          content: { symbol: "وامید", name: "گروه مدیریت سرمایه گذاری امید" },
        },
        {
          id: "34571742534514939",
          content: { symbol: "صشستا112", name: "صکوک اجاره شستا112-6ماهه18%" },
        },
        {
          id: "35434372538626163",
          content: { symbol: "صشستا311", name: "صکوک اجاره شستا311-بدون ضامن" },
        },
        {
          id: "2512214120008228",
          content: { symbol: "سینا", name: "مالی و سرمایه گذاری سینا" },
        },
        {
          id: "43018132346389996",
          content: { symbol: "صملی404", name: "صکوک اجاره صملی404-6ماهه18%" },
        },
        {
          id: "7505613986635677",
          content: { symbol: "تدبیر", name: "گروه سرمایه گذاری تدبیر" },
        },
        {
          id: "25133129074902751",
          content: { symbol: "چابهار", name: "صدرا امید چابهار" },
        },
        {
          id: "65883838195688438",
          content: { symbol: "خودرو", name: "ایران خودرو" },
        },
        {
          id: "44891482026867833",
          content: { symbol: "خساپا", name: "سایپا" },
        },
        {
          id: "48990026850202503",
          content: { symbol: "خگستر", name: "گسترشسرمایهگذاریایرانخودرو" },
        },
        {
          id: "42354736493447489",
          content: { symbol: "خپارس", name: "پارس خودرو" },
        },
        {
          id: "17834623106317041",
          content: { symbol: "خنصیر", name: "مهندسینصیرماشین" },
        },
        {
          id: "26824673819862694",
          content: { symbol: "خبهمن", name: "گروهبهمن" },
        },
        {
          id: "32821908911812078",
          content: { symbol: "خزر", name: "فنرسازیزر" },
        },
        {
          id: "2589887561569709",
          content: { symbol: "خزامیا", name: "زامیاد" },
        },
        {
          id: "59217041815333317",
          content: { symbol: "خکار", name: "ایرکا پارت صنعت" },
        },
        {
          id: "50185721305191887",
          content: { symbol: "ختور", name: "رادیاتور ایران" },
        },
        {
          id: "16315910303613772",
          content: { symbol: "خاور", name: "ایران خودرو دیزل" },
        },
        {
          id: "70289374539527245",
          content: { symbol: "ختوقا", name: "قطعات اتومبیل ایران" },
        },
        {
          id: "39436183727126211",
          content: { symbol: "خمحرکه", name: "نیرو محرکه" },
        },
        {
          id: "17330546482145553",
          content: { symbol: "خمهر", name: "مهرکامپارس" },
        },
        {
          id: "14957056743925737",
          content: { symbol: "خلنت", name: "لنت ترمزایران" },
        },
        {
          id: "12752224677923341",
          content: { symbol: "خریخت", name: "صنایعریختهگریایران" },
        },
        {
          id: "42075223783409640",
          content: { symbol: "خاذین", name: "سایپاآذین" },
        },
        {
          id: "57273529732791251",
          content: { symbol: "خموتور", name: "موتورسازانتراکتورسازیایران" },
        },
        {
          id: "7457232989848872",
          content: { symbol: "خمحور", name: "تولیدمحورخودرو" },
        },
      ],
    };
  },
};
</script>

<style scoped>
table td {
  border: unset !important;
}

.wrapper {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #f5f5f5 !important;
}

.wrapper-enable {
  background: #fff;
  border-color: #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);*/
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.wrapper-enable:hover {
  /*box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 2px 6px rgba(0,0,0,0.23),*/
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.dropdown {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 0 solid transparent;
  background: transparent;
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  line-height: 1.5em;
  outline: none;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-list {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: transparent;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}

.dropdown-item-id {
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0;
}

.disable {
  color: #ccc;
  cursor: text;
}

.enable {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
.mdb-table table {
  margin-bottom: 0 !important;
}
</style>
