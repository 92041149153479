import HomeView from "../views/HomeView.vue";
import LaboratoryView from "../views/services/Lab.vue";
import ServiceWiki from "../views/services/Wiki.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/codal",
    name: "codal",
    component: () => import("../views/codal/CodalView.vue"),
  },
  {
    path: "/tsetmc",
    name: "tsetmc",
    component: () => import("../views/tsetmc/ReportView.vue"),
  },
  {
    path: "/services/lab",
    name: "lab",
    component: LaboratoryView,
  },
  {
    path: "/services",
    name: "wiki",
    component: ServiceWiki,
  },
  // {
  //   path: "/codal/announcement/:TracingNo/edit",
  //   name: "AnnouncementEdit",
  //   component: () => import("../views/codal/announcement/Edit.vue"),
  // },
  // {
  //   path: "/codal/announcement/:TracingNo",
  //   name: "Announcement",
  //   component: () => import("../views/codal/announcement/AnnouncementView.vue"),
  // },
  {
    path: "/support",
    name: "support",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SupportView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
