<template>
  <slide-navbar></slide-navbar>
  <router-view />
</template>

<script>
import SlideNavbar from "./components/SlideNavbar.vue";

export default {
  name: "App",
  components: {
    SlideNavbar,
  },
};
</script>

<style>
body {
  font-family: IRANSans !important;
  font-weight: 300;
  direction: rtl;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f5f6 !important;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

* {
  color: #232323;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
