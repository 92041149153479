<template>
  <div class="container-fluid d-flex flex-grow-1 flex-column my-4">
    <div class="row d-flex flex-grow-1">
      <div class="col-12 d-flex flex-column text-center justify-content-center">
        <div id="logo">
          <img alt="logo" src="../assets/img/logo.png" style="width: 200px" />
          <!-- <p class="text-dark text-center">افزونه بورس تهران</p> -->
        </div>
        <dropdown-list
          class="mt-4"
          :keys="['symbol']"
          placeholder="نماد یا نام شرکت"
        >
        </dropdown-list>
      </div>
    </div>
    <div class="row d-flex flex-grow-1"></div>

    <div class="row d-flex justify-content-center">
      <footer class="text-small text-dark text-center py-3">
        آخرین به روز رسانی پایگاه داده در تاریخ
        {{ formatDate() }}
      </footer>
    </div>
  </div>
</template>

<script>
import DropdownList from "../components/DropdownList.vue";

export default {
  name: "HomeView",
  components: {
    DropdownList,
  },
  data() {
    return {
      lastupdate: "",
    };
  },
  methods: {
    formatDate() {
      return new Intl.DateTimeFormat("fa-IR").format(new Date());
    },
  },
};
</script>
