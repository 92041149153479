<template>
  <div class="container mt-55">
    <div class="row">
      <div class="col-12 text-right">
        <div class="card">
          <div class="card-header">
            <h5>
              <i class="fad fa-vials font-18 text-light-50 ml-10"></i>
              آزمایشگاه
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LaboratoryView",
};
</script>

<style scoped></style>
